/*
@File: Tezor HTML Template

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************
** - Default CSS Style 
** - Section Title Style
** - Navbar Area Style
** - Main Banner Area CSS Style
** - Inner Banner Style
** - Other Area Style

*******************************************
/*

/*================================================
Default CSS
=================================================*/
// @import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

// :root {
//   --bodyFonts: "Poppins", sans-serif;
//   --pinkColor: #f14d5d;
//   --titleColor: #353840;
//   --bodyColor: #666666;
//   --lightblueColor: #8d99ff;
//   --whiteColor: #ffffff;
//   --primary-text-color: #353840;
//   --primary-heading-color: rgba(4, 17, 29, 1);
//   --fontSize: 16px;
//   --transition: 0.5s;
//   --navbar-bg-desktop: #fff;
//   --primary-border-color: rgba(4, 17, 29, 0.15);
//   --primary-boder-light: rgb(229, 232, 235);
//   --primary-text-muted: rgba(4, 17, 29, 0.5);
//   --danger-color: #dc3545;
//   --primary-modal-color: #ffffff;
//   --scroll-bar-color : #555;
// }

body {
  padding: 0;
  margin: 0;
  line-height: 1.6;
  font-weight: 400;
  font: {
    size: var(--fontSize);
    family: var(--bodyFonts);
  }
  --bodyFonts: "Poppins", sans-serif;
  --pinkColor: #f14d5d;
  --titleColor: #353840;
  --bodyColor: #ffff;
  --lightblueColor: #8d99ff;
  --whiteColor: #ffffff;
  --primary-text-color: #353840;
  --primary-heading-color: rgba(4, 17, 29, 1);
  --fontSize: 16px;
  --transition: 0.5s;
  --navbar-bg-desktop: #fff;
  --primary-border-color: rgba(4, 17, 29, 0.15);
  --primary-boder-light: rgb(229, 232, 235);
  --primary-text-muted: rgba(4, 17, 29, 0.5);
  --danger-color: #dc3545;
  --primary-modal-color: #ffffff;
  --scroll-bar-color: #555;
  --primary-box-color: #fff;
  --primary-font-color: #353840;
  --header-search-bar-color: #ffffff;
  --primary-card-color: #fff;
  --card-footer-gradient: linear-gradient(
    rgba(229, 232, 235, 0.392) 0%,
    rgb(255, 255, 255) 50%
  );
  --primary-hover-color: rgf8f8f8b(251, 253, 255);
  --color-shade-4: #F1F3F4;
  --icon-color: #353840;
  --properties-border-color: #15b2e5;
  --properties-bg-color: rgba(21, 178, 229, 0.06);
  --accordion-heading-bg: #fff;
  --tabs-active-bg: aliceblue;
  --primary-button-color: #fff;
  --header-dropdown-bg: #fff;
  --according-open-bg: rgb(251, 253, 255);
  --properties-primary: rgb(21, 178, 229);
  --footer-bg: #eff0f4;
  --static-page-heading-color : #fff; 
  --static-white-color : #fff;

  background-color: var(--bodyColor);
}

body.dark-mode {
  --pinkColor: #f14d5d;
  --titleColor: #fff;
  --bodyColor: #202225;
  --lightblueColor: #8d99ff;
  --whiteColor: #000;
  --primary-text-color: #fff;
  --primary-heading-color: #fff;
  --fontSize: 16px;
  --transition: 0.5s;
  --navbar-bg-desktop: #04111d;
  --primary-border-color: #303339;
  --primary-boder-light: rgb(21, 27, 34);
  --primary-text-muted: rgba(255, 255, 255, 0.5);
  --danger-color: #dc3545;
  --primary-modal-color: #353840;
  --scroll-bar-color: #000;
  --primary-box-color: #000;
  --primary-font-color: #ffffff;
  --header-search-bar-color: #353840;
  --primary-card-color: #303339;
  --card-footer-gradient: linear-gradient(
    rgba(21, 27, 34, 0.392) 0%,
    rgb(48, 51, 57) 50%
  );
  --primary-hover-color: #353840;
  --color-shade-4: #303339;
  --icon-color: #fff;
  --properties-border-color: #15b2e5;
  --properties-bg-color: rgba(21, 178, 229, 0.06);
  --accordion-heading-bg: rgb(38, 43, 47);
  --tabs-active-bg: #303339;
  --primary-button-color: #fff;
  --header-dropdown-bg: #303339;
  --according-open-bg: #222831;
  --properties-primary: rgb(21, 178, 229);
  --footer-bg: rgb(4, 17, 29);
  --static-page-heading-color : #fff;
  --static-white-color : #fff;

  background-color: var(--bodyColor);
}

p {
  color: var(--primary-font-color);
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}
a {
  display: inline-block;
  transition: var(--transition);
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}
button {
  outline: 0;
  &:focus {
    outline: 0;
    border: 0;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: var(--titleColor);
  line-height: 1.4;
}
h3 {
  font-size: 22px;
}
.d-table {
  width: 100%;
  height: 100%;
}
.d-table-cell {
  display: table-cell;
  vertical-align: middle;
}
img {
  max-width: 100%;
}
.pt-150 {
  padding-top: 150px;
}
.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.pt-100 {
  padding-top: 100px;
}
.pb-100 {
  padding-bottom: 100px !important;
}
.ptb-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}
.pt-70 {
  padding-top: 70px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-45 {
  padding-top: 45px;
}
.pt-20 {
  padding-top: 20px;
}
.pl-20 {
  padding-left: 20px;
}
.pr-20 {
  padding-right: 20px;
}
.pb-10 {
  padding-bottom: 10px;
}
.ml-20 {
  margin-left: 20px;
}
.mr-20 {
  margin-right: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.border-radius-5 {
  border-radius: 5px !important;
}
.border-radius-50 {
  border-radius: 50px !important;
}
.box-shadow {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
}

/*================================================
Default Btn CSS
=================================================*/
.default-btn {
  padding: 12px 25px 10px;
  text-align: center;
  color: var(--primary-button-color) !important;
  font-size: var(--fontSize);
  font-weight: 600;
  transition: var(--transition);
  display: inline-block;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 8px;
  z-index: 0;
  background: var(--pinkColor);
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 550px;
    height: 550px;
    margin: auto;
    background: var(--lightblueColor);
    border-radius: 8px;
    z-index: -1;
    transform-origin: top center;
    transform: translateX(-50%) translateY(-5%) scale(0.4);
    transition: transform 0.9s;
  }
  &:hover {
    color: var(--whiteColor) !important;
    &:before {
      transition: transform 1s;
      transform: translateX(-45%) translateY(0) scale(1);
      transform-origin: bottom center;
    }
  }
  &.two {
    background: var(--lightblueColor);
    &::before {
      background-color: var(--pinkColor);
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

/*================================
Section Title Style 
===================================*/
.section-title {
  span {
    font-size: inherit;
    color: var(--pinkColor);
    display: inline-block;
    margin-bottom: 15px;
  }
  h2 {
    font-size: 40px;
    margin-top: 0;
    line-height: 1.2;
    color: var(--titleColor);
    margin-bottom: 0;
    text-transform: capitalize;
  }
  p {
    padding-top: 15px;
    margin-bottom: 0;
  }
}

/*================================
Navbar Area
===================================*/
.desktop-nav {
  background-color: var(--whiteColor);
  z-index: 1000;
  padding: {
    top: 0;
    bottom: 0;
  }
  .navbar {
    gap: 1em;
    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;
    }
    .navbar-nav {
      margin: {
        left: auto;
        right: auto;
      }
      .nav-item {
        position: relative;
        padding: {
          bottom: 30px;
          top: 30px;
          right: 0;
          left: 0;
        }
        a {
          color: var(--primary-text-color);
          position: relative;
          z-index: 1;
          font-family: var(--bodyFonts);
          padding: {
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
          }
          margin: {
            left: 15px;
            right: 15px;
          }
          i {
            font-size: 18px;
            position: relative;
            top: 5px;
            transition: var(--transition);
            line-height: normal;
          }
          &:hover,
          &:focus,
          &.active {
            color: var(--pinkColor);
            &::before {
              width: 100%;
            }
            i {
              transform: rotate(360deg);
            }
          }
          &:hover,
          &:focus {
            i {
              transform: rotate(360deg);
            }
          }
        }
        &:last-child {
          a {
            margin-right: 0;
          }
        }
        &:first-child {
          a {
            margin-left: 0;
          }
        }
        &:hover,
        &:focus,
        &.active {
          a {
            color: var(--pinkColor);
          }
        }
        .dropdown-menu {
          box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
          transition: all 0.3s ease-in-out;
          background: var(--whiteColor);
          border: none;
          border-top: 2px solid var(--pinkColor);
          position: absolute;
          visibility: hidden;
          transform-origin: bottom;
          border-radius: 5px;
          display: block;
          width: 250px;
          z-index: 99;
          opacity: 0;
          top: 80px;
          left: 0;
          transform: scaleY(0);
          transition: var(--transition);
          padding: {
            top: 2px;
            left: 5px;
            right: 5px;
            bottom: 0;
          }
          li {
            padding: 0;
            border-bottom: 1px solid var(--primary-boder-light);
            &:last-child {
              border: none;
            }
            a {
              color: var(--titleColor);
              padding: 1rem;
              margin: 0;
              line-height: 1;
              text-transform: capitalize;
              &:before {
                display: none;
              }
              &:hover,
              &:focus,
              &.active {
                color: var(--pinkColor);
              }
              i {
                float: right;
                position: relative;
                top: 0;
              }
            }
            .dropdown-menu {
              left: 245px;
              top: 0;
              opacity: 0;
              visibility: hidden;
              margin-top: 11px;
              li {
                a {
                  color: var(--titleColor);
                  &:hover,
                  &:focus,
                  &.active {
                    color: var(--pinkColor);
                  }
                }
                .dropdown-menu {
                  left: 245px;
                  top: 0;
                  opacity: 0;
                  visibility: hidden;
                  li {
                    a {
                      color: var(--titleColor);
                      font-size: 15px;
                      &:hover,
                      &:focus,
                      &.active {
                        color: var(--pinkColor);
                      }
                    }
                    .dropdown-menu {
                      left: -250px;
                      top: 0;
                      opacity: 0;
                      visibility: hidden;
                      li {
                        a {
                          color: var(--titleColor);
                          &:hover,
                          &:focus,
                          &.active {
                            color: var(--pinkColor);
                          }
                        }
                        .dropdown-menu {
                          left: -250px;
                          top: 0;
                          opacity: 0;
                          visibility: hidden;
                          li {
                            a {
                              color: var(--titleColor);

                              &:hover,
                              &:focus,
                              &.active {
                                color: var(--pinkColor);
                              }
                            }
                            .dropdown-menu {
                              left: -250px;
                              top: 0;
                              opacity: 0;
                              visibility: hidden;
                              li {
                                a {
                                  color: var(--titleColor);

                                  &:hover,
                                  &:focus,
                                  &.active {
                                    color: var(--pinkColor);
                                  }
                                }
                                .dropdown-menu {
                                  left: -250px;
                                  top: 0;
                                  opacity: 0;
                                  visibility: hidden;
                                  li {
                                    a {
                                      color: var(--titleColor);
                                      &:hover,
                                      &:focus,
                                      &.active {
                                        color: var(--pinkColor);
                                      }
                                    }
                                  }
                                }
                                &.active {
                                  a {
                                    color: var(--pinkColor);
                                  }
                                }
                                &:hover {
                                  .dropdown-menu {
                                    opacity: 1;
                                    visibility: visible;
                                    top: -15px;
                                  }
                                }
                              }
                            }
                            &.active {
                              a {
                                color: var(--pinkColor);
                              }
                            }
                            &:hover {
                              .dropdown-menu {
                                opacity: 1;
                                visibility: visible;
                                top: -15px;
                              }
                            }
                          }
                        }
                        &.active {
                          a {
                            color: var(--pinkColor);
                          }
                        }
                        &:hover {
                          .dropdown-menu {
                            opacity: 1;
                            top: -15px;
                            visibility: visible;
                          }
                        }
                      }
                    }
                    &.active {
                      a {
                        color: var(--pinkColor);
                      }
                    }
                    &:hover {
                      .dropdown-menu {
                        opacity: 1;
                        top: -15px;
                        visibility: visible;
                      }
                    }
                  }
                }
                &.active {
                  a {
                    color: var(--pinkColor);
                  }
                }
                &:hover {
                  .dropdown-menu {
                    opacity: 1;
                    visibility: visible;
                    top: -15px;
                  }
                }
              }
            }
            &.active {
              a {
                color: var(--pinkColor);
              }
            }
            &:hover {
              .dropdown-menu {
                opacity: 1;
                visibility: visible;
                top: -15px;
              }
            }
          }
        }
        &:hover {
          .dropdown-menu {
            top: 100%;
            opacity: 1;
            margin-top: 0;
            visibility: visible;
            transform: scaleY(1);
          }
        }
      }
    }
  }
}
.desktop-nav-one {
  position: fixed;
  background-color: var(--navbar-bg-desktop);
  left: 0;
  right: 0;
  border-bottom: none;
  .navbar {
    .navbar-nav {
      .nav-item {
        a {
          color: var(--primary-text-color);
        }
      }
    }
  }
}

.navbar-area {
  background-color: var(--whiteColor);
  display: contents;
  &.is-sticky {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    position: fixed;
    background-color: var(--whiteColor) !important;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    .desktop-nav-one {
      top: 0;
      left: 0;
      width: 100%;
      z-index: 999;
      position: fixed;
      background-color: #0a1831;
      animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
      border: none;
    }
  }
}
.mobile-responsive-nav {
  display: none;
}

.nav-widget-form {
  flex: 1 1 auto;

  .search-form {
    position: relative;
    margin: 0 auto;
    border-radius: 0;
    border: none;
    width: 250px;
    border-radius: 15px;
    width: 100%;
    .form-control {
      background-color: var(--header-search-bar-color);
      border-radius: 0.5rem;
      border: 1px solid var(--primary-border-color);
      height: 50px;
      line-height: 50px;
      font-size: 15px;
      margin: 0;
      padding: 0 2.5rem;
      color: var(--primary-text-color);

      &::-webkit-input-placeholder,
      &:-ms-input-placeholder,
      &::-ms-input-placeholder,
      &::placeholder {
        color: var(--whiteColor);
      }
      &:focus {
        outline: none;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 -2px 6px rgba(0, 0, 0, 0.1);
      }
      &:active {
        outline: none;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 -2px 6px rgba(0, 0, 0, 0.1);
      }
    }

    .search-clear {
      position: absolute;
      content: "";
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1em;
      cursor: pointer;
      display: grid;
      place-items: center;

      i {
        color: var(--primary-text-color);
      }
    }

    .search-icon {
      position: absolute;
      content: "";
      left: 0.75rem;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.25em;
      cursor: pointer;

      svg {
        fill: var(--primary-text-color);
      }
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
      border: 0;
      border-radius: 0;
      background-color: transparent;
      color: var(--primary-text-color);
      height: 50px;
      line-height: 50px;
      font-size: 20px;
      padding: 0 15px;
      transition: var(--transition);
      border-radius: 0 5px 5px 0;
      &:hover {
        background: var(--pinkColor);
      }
    }
  }
  &.nav-widget-form-bg {
    .form-control {
      background-color: var(--bodyColor);
      border-radius: 15px;
      color: var(--bodyColor);
      &::-webkit-input-placeholder,
      &:-ms-input-placeholder,
      &::-ms-input-placeholder,
      &::placeholder {
        color: var(--bodyColor);
      }
    }
    button {
      color: var(--pinkColor);
      &:hover {
        background: var(--pinkColor);
        color: var(--whiteColor);
      }
    }
  }

  &.hide-mobile {
    @media screen and (max-width: 991px) {
      display: none;
    }
  }
}

.others-options {
  .optional-item-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      display: inline-block;
      margin-right: 20px;
      a {
        font-size: 15px;
        color: var(--whiteColor);
        position: relative;
        transition: var(--transition);
        padding: 12px 20px 10px;
        background-color: #14233d;
        border-radius: 15px;
        &:hover {
          background-color: var(--pinkColor);
        }
        &.active {
          background-color: var(--pinkColor);
          transition: var(--transition);
          &:hover {
            background-color: #14233d;
          }
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.mobile-nav {
  position: absolute;
  top: 21px;
  right: 105px;
  display: none;
  .search-btn {
    position: relative;
    z-index: 999;
    a {
      i {
        font-size: 18px;
        color: var(--pinkColor);
        transition: 0.6s;
        &:hover {
          color: var(--lightblueColor);
        }
      }
    }
  }
}

.searchmodal {
  .modal-dialog {
    width: 100%;
    height: 100%;
    max-width: 100%;
    margin: 0;
    display: table;
  }
}

.modal-content {
  height: 100%;
  border-radius: 0;
  border: none;
  display: table-cell;
  vertical-align: middle;
  background-color: #222;
  opacity: 0.9;
}

.modal-body {
  max-width: 700px;
  margin: auto;
  padding: 20px;
}

.modal-header {
  border: none;
  button {
    position: relative;
    &.close {
      position: absolute;
      right: 25px;
      top: 25px;
      display: inline-block;
      height: 40px;
      width: 40px;
      line-height: 40px;
      background-color: var(--whiteColor);
      border-radius: 0;
      color: var(--pinkColor);
      text-align: center;
      border: none;
      font-size: 24px;
      transition: 0.6s;
      &:hover,
      &:focus {
        background-color: var(--pinkColor);
        color: var(--whiteColor);
      }
    }
  }
}

.modal-search-form {
  position: relative;
  .search-field {
    background-color: transparent;
    height: 50px;
    padding: 8px 15px;
    border: none;
    border: 1px solid var(--whiteColor);
    width: 100%;
    display: block;
    outline: 0;
    transition: 0.6s;
    border-radius: 0;
    color: var(--whiteColor);
    &::-webkit-input-placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder,
    &::placeholder {
      color: var(--whiteColor);
      transition: 0.6s;
    }
    &:focus {
      border-color: var(--pinkColor);
      &::-webkit-input-placeholder,
      &:-ms-input-placeholder,
      &::-ms-input-placeholder,
      &::placeholder {
        color: transparent;
      }
    }
  }
  button {
    border: none;
    background-color: var(--whiteColor);
    color: var(--pinkColor);
    height: 40px;
    width: 40px;
    position: absolute;
    right: 5px;
    padding: 0;
    transition: 0.6s;
    top: 50%;
    transform: translateY(-50%);
    font-size: 17px;
    border-radius: 0;
    cursor: pointer;
    i {
      position: absolute;
      left: 0;
      right: 0;
      top: 52%;
      transform: translateY(-52%);
    }
    &:hover,
    &:focus {
      background-color: var(--pinkColor);
      color: var(--whiteColor);
    }
  }
}

.side-nav-responsive {
  display: none;
  .dot-menu {
    padding: 0 10px;
    height: 30px;
    cursor: pointer;
    z-index: 999;
    position: absolute;
    right: 55px;
    top: -38px;
    .circle-inner {
      display: flex;
      align-items: center;
      height: 30px;
      .circle {
        height: 5px;
        width: 5px;
        border-radius: 100%;
        margin: 0 2px;
        transition: 0.7s;
        background-color: var(--pinkColor);
      }
    }
    &:hover {
      .circle-inner {
        .circle {
          background-color: var(--lightblueColor);
        }
      }
    }
  }
  .container-max {
    position: relative;
    display: flex;
    .container {
      position: absolute;
      top: 10px;
      right: 0;
      max-width: 277px;
      margin-left: auto;
      opacity: 0;
      visibility: hidden;
      transition: 0.7s;
      transform: scaleX(0);
      z-index: 2;
      padding-left: 15px;
      padding-right: 15px;
    }
    .container.active {
      opacity: 1;
      visibility: visible;
      transform: scaleX(1);
    }
  }
  .side-nav-inner {
    padding: 6px;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.09);
    background-color: var(--whiteColor);
    .side-nav {
      background-color: #0a1831;
      padding: 7px 0px 10px 7px;
      .side-nav-item {
        display: inline-block;
        margin-left: 10px;
        .optional-item-list {
          margin: 0;
          padding: 0;
          list-style-type: none;
          li {
            display: inline-block;
            margin-right: 10px;
            a {
              font-size: 14px;
              color: var(--whiteColor);
              position: relative;
              transition: var(--transition);
              padding: 10px 12px 8px;
              background-color: #14233d;
              border-radius: 15px;
              &:hover {
                background-color: var(--pinkColor);
              }
              &.active {
                background-color: var(--pinkColor);
                transition: var(--transition);
                &:hover {
                  background-color: #14233d;
                }
              }
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
/*================================
Navbar Area End
===================================*/

/*=================================
Main Banner Area 
====================================*/
.banner-area {
  background-color: var(--bodyColor);
  padding: 150px 0 70px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.banner-content {
  span {
    font-size: 18px;
    color: var(--pinkColor);
    display: block;
    margin-bottom: 10px;
  }
  h1 {
    font-size: 56px;
    margin-bottom: 20px;
    line-height: 1.2;
    color: var(--primary-heading-color);
  }
  p {
    margin-bottom: 30px;
    max-width: 700px;
    color: var(--primary-text-color);
  }
  .banner-btn {
    .two {
      margin-left: 20px;
    }
  }
}

.banner-card {
  margin-bottom: 30px;
  border-radius: 5px;

  &.banner-card-mt {
    margin-top: 50px;
    margin-bottom: 0;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  }
  .banner-card-img {
    position: relative;
    width: 100%;
    min-height: 400px;
    max-height: 400px;

    img {
      object-fit: cover;
      width: 100%;
      height: 400px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    .banner-card-content {
      position: absolute;
      padding: 15px;
      bottom: 20px;
      left: 20px;
      right: 20px;
      z-index: 1;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(250, 250, 250, 0.35);
        backdrop-filter: blur(25px);
        border-radius: 5px;
        border: 1px solid rgba(250, 250, 250, 0.5);
      }
      .card-left {
        span {
          font-size: 15px;
          color: var(--whiteColor);
          margin-bottom: 7px;
          display: block;
          font-weight: 400;
        }
        h3 {
          font-size: 20px;
          color: var(--whiteColor);
          margin-bottom: 0;
        }
      }
      .card-right {
        h3 {
          font-size: 15px;
          color: var(--whiteColor);
          margin-bottom: 7px;
          display: block;
          font-weight: 400;
        }
        .timer-text {
          font-size: 20px;
          color: var(--whiteColor);
          margin-bottom: 0;
          font-weight: 600;
          font-family: var(--bodyFonts);
        }
      }
    }
  }
  .content {
    padding: 15px 10px;
    background-color: var(--color-shade-4);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0 0 5px 5px;
    position: relative;

    .banner-user-list {
      position: relative;
      padding-left: 70px;
      .banner-user-list-img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: var(--transition);
        left: 0;
        img {
          border-radius: 50%;
        }
        i {
          position: absolute;
          bottom: 5px;
          right: -10px;
          text-align: center;
          width: 22px;
          height: 22px;
          line-height: 22px;
          font-size: 14px;
          border-radius: 50px;
          background-color: var(--lightblueColor);
          color: var(--whiteColor);
        }
      }

      h3 {
        font-size: 18px;
        margin-bottom: 3px;
        a {
          color: var(--titleColor);
          transition: var(--transition);
        }
      }
      span {
        font-size: 14px;
        color: var(--bodyColor);
        a {
          color: var(--bodyColor);
          transition: var(--transition);
          &:hover {
            color: var(--pinkColor);
          }
        }
      }
      &:hover {
        h3 {
          a {
            color: var(--pinkColor);
          }
        }
      }
    }
    .banner-user-btn {
      width: 32px;
      height: 32px;
      line-height: 32px;
      font-size: 18px;
      border-radius: 50px;
      background-color: var(--pinkColor);
      color: var(--whiteColor);
      text-align: center;
      display: inline-block;
      transition: var(--transition);
      &:hover {
        background-color: var(--lightblueColor);
      }
    }
    .default-btn {
      top: 50%;
      opacity: 0;
      right: 10px;
      visibility: hidden;
      position: absolute;
      transform: translateY(-50%);
      transition: var(--transition);
    }
    &:hover {
      .banner-user-list {
        h3 {
          a {
            color: var(--pinkColor);
          }
        }
        .banner-user-list-img {
          transform: translateY(-52%);
        }
      }
      .default-btn {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &:hover {
    .content {
      .default-btn {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.banner-shape {
  .shape-circle1 {
    position: absolute;
    top: 20%;
    left: 20%;
    animation: movebounce-down 9s infinite;
  }
  .shape-circle2 {
    position: absolute;
    bottom: 15%;
    left: 40%;
    animation: movebounce-up 9s infinite;
  }
  .shape-bg {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 20%;
  }
  .shape-in1 {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .shape-in2 {
    position: absolute;
    bottom: 50%;
    right: 3%;
  }
}

.banner-area-two {
  position: relative;
  z-index: 1;
  background-image: url(./images/home-two/home-two-bg.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 70px 0;
}

.banner-content-two {
  h1 {
    font-size: 62px;
    margin-bottom: 20px;
    line-height: 1.2;
    color: var(--titleColor);
    max-width: 600px;
  }
  p {
    margin-bottom: 30px;
    max-width: 700px;
    color: #0c0b0b;
  }
  .banner-btn {
    .two {
      margin-left: 20px;
    }
  }
}

.banner-item-slider {
  position: relative;
  z-index: 1;
  padding: 0 30px 23px;
  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 70%;
    background-color: rgba(250, 250, 250, 0.35);
    backdrop-filter: blur(25px);
    border-radius: 5px;
    border: 1px solid rgba(250, 250, 250, 0.5);
  }
  .owl-dots {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    position: absolute;
    bottom: 40px;
    right: 30px;
    line-height: 0;
    .owl-dot {
      span {
        background-color: var(--whiteColor) !important;
        transition: 0.7s;
        margin: 0 7px;
        width: 7px !important;
        height: 7px !important;
        position: relative;
        z-index: 1;
        border-radius: 50px;
      }
      &.active {
        span {
          background-color: var(--pinkColor) !important;
          width: 35px !important;
          height: 7px !important;
        }
      }
      &:hover {
        span {
          background-color: var(--pinkColor) !important;
          width: 35px !important;
          height: 7px !important;
        }
      }
    }
  }
}
.banner-item {
  border-radius: 5px;
  position: relative;
  z-index: 1;
  .banner-item-img {
    position: relative;
    img {
      border-radius: 5px;
    }
    .banner-item-user {
      position: absolute;
      z-index: 1;
      top: 25px;
      left: 20px;
      background-color: var(--whiteColor);
      padding: 7px 12px;
      display: inline-block;
      border-radius: 50px;
      .banner-item-user-option {
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
          border-radius: 50%;
          margin-right: 10px;
          width: 30px !important;
          height: 30px !important;
        }
        span {
          font-size: 14px;
          color: var(--titleColor);
          font-weight: 600;
          transition: var(--transition);
          line-height: 1;
        }
      }
      &:hover {
        .banner-item-user-option {
          span {
            color: var(--pinkColor);
          }
        }
      }
    }
  }
  .content {
    position: relative;
    h3 {
      margin-top: 25px;
      margin-bottom: 20px;
    }
    p {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      padding: 8px 22px 6px;
      font-size: 16px;
      color: var(--bodyColor);
      background-color: rgba(250, 250, 250, 0.4);
      border-radius: 50px;
      i {
        position: relative;
        top: 3px;
        margin-right: 3px;
        color: var(--pinkColor);
      }
    }
    .banner-item-content {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      .item-left {
        margin-right: 200px;
        span {
          font-size: 15px;
          color: var(--titleColor);
          margin-bottom: 10px;
          display: block;
          font-weight: 400;
        }
        .item-left-eth {
          font-size: 20px;
          color: var(--titleColor);
          margin-bottom: 0;
          margin-top: 0;
        }
      }
      .item-right {
        .item-remaining {
          font-size: 15px;
          color: var(--titleColor);
          margin-bottom: 10px;
          display: block;
          font-weight: 400;
          margin-top: 0;
        }
        .timer-text {
          font-size: 20px;
          color: var(--titleColor);
          margin-bottom: 0;
          font-weight: 600;
          font-family: var(--bodyFonts);
        }
      }
    }
    .place-btn {
      padding: 10px 25px 8px;
      text-align: center;
      color: var(--whiteColor) !important;
      font-size: 15px;
      font-weight: 600;
      transition: var(--transition);
      display: inline-block;
      align-items: center;
      justify-content: center;
      position: relative;
      border-radius: 50px;
      z-index: 0;
      background: var(--pinkColor);
      overflow: hidden;
      white-space: nowrap;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        width: 550px;
        height: 550px;
        margin: auto;
        background: var(--lightblueColor);
        border-radius: 50px;
        z-index: -1;
        transform-origin: top center;
        transform: translateX(-50%) translateY(-5%) scale(0.4);
        transition: transform 0.9s;
      }
      &:hover {
        color: var(--whiteColor) !important;
        &:before {
          transition: transform 1s;
          transform: translateX(-45%) translateY(0) scale(1);
          transform-origin: bottom center;
        }
      }
    }
  }
}

.banner-area-three {
  background-image: url(./images/home-three-bg.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 150px 0;
}

.banner-content-three {
  padding: 40px;
  position: relative;
  z-index: 1;
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(250, 250, 250, 0.25);
    backdrop-filter: blur(25px);
    border-radius: 15px;
    border: 1px solid rgba(250, 250, 250, 0.5);
  }
  h1 {
    font-size: 62px;
    margin-bottom: 20px;
    line-height: 1.2;
    color: var(--whiteColor);
  }
  p {
    margin-bottom: 30px;
    max-width: 680px;
    color: var(--whiteColor);
    margin-left: auto;
    margin-right: auto;
  }
  .banner-btn {
    .two {
      margin-left: 20px;
    }
  }
}
/*=================================
Main Banner Area End
====================================*/

/*================================
Inner Banner Area
===================================*/
.inner-banner {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  .inner-title {
    padding-top: 140px;
    padding-bottom: 140px;
    position: relative;
    z-index: 1;
    text-transform: capitalize;
    h3 {
      font-size: 45px;
      color: var(--static-page-heading-color);
      line-height: 1.2;
      margin-bottom: 15px;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        font-size: 18px;
        color: var(--static-page-heading-color);
        display: inline-block;
        position: relative;
        margin-right: 35px;
        font-weight: 400;
        &:last-child {
          margin-right: 0;
          &::before {
            display: none;
          }
        }
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -22px;
          height: 5px;
          width: 5px;
          border-radius: 50px;
          background-color: var(--whiteColor);
        }
        a {
          color: var(--pinkColor);
          transition: var(--transition);
          &:hover {
            color: var(--whiteColor);
          }
        }
      }
    }
  }
}
.inner-bg1 {
  background-image: url(./images/inner-banner/inner-banner1.jpg);
}
.inner-bg2 {
  background-image: url(./images/inner-banner/inner-banner2.jpg);
}
.inner-bg3 {
  background-image: url(./images/inner-banner/inner-banner3.jpg);
}
.inner-bg4 {
  background-image: url(./images/inner-banner/inner-banner4.jpg);
}
.inner-bg5 {
  background-image: url(./images/inner-banner/inner-banner5.jpg);
}
.inner-bg6 {
  background-image: url(./images/inner-banner/inner-banner6.jpg);
}
.inner-bg7 {
  background-image: url(./images/inner-banner/inner-banner7.jpg);
}
.inner-bg8 {
  background-image: url(./images/inner-banner/inner-banner8.jpg);
}
.inner-bg9 {
  background-image: url(./images/inner-banner/inner-banner9.jpg);
}
.inner-bg10 {
  background-image: url(./images/inner-banner/inner-banner10.jpg);
}
.inner-bg11 {
  background-image: url(./images/inner-banner/inner-banner11.jpg);
}
.inner-bg12 {
  background-image: url(./images/inner-banner/inner-banner12.jpg);
}
.inner-bg13 {
  background-image: url(./images/inner-banner/inner-banner13.jpg);
}

.inner-shape {
  img {
    &:nth-child(1) {
      position: absolute;
      bottom: 70px;
      right: 10%;
      animation: movebounce-up 9s infinite;
    }
    &:nth-child(2) {
      position: absolute;
      top: 120px;
      right: 0;
      animation: movebounce-down 9s infinite;
    }
  }
}

.about-content {
  margin-bottom: 30px;
  max-width: 1070px;
}
.about-img {
  margin-bottom: 30px;
  img {
    border-radius: 15px;
  }
}
.about-bottom-content {
  margin-bottom: 30px;
}
.about-widget-area {
  background-color: #f6f6f6;
}
.about-card {
  background-color: var(--whiteColor);
  padding: 30px 20px 30px 95px;
  border-radius: 15px;
  position: relative;
  margin-bottom: 30px;
  .number {
    position: absolute;
    top: 30px;
    left: 20px;
    width: 56px;
    height: 56px;
    line-height: 56px;
    text-align: center;
    font-size: 18px;
    background-color: rgba(#f14d5d, 0.15%);
    color: var(--pinkColor);
    border-radius: 5px;
    transition: var(--transition);
    font-weight: 700;
  }
  h3 {
    margin-bottom: 10px;
    transition: var(--transition);
  }
  p {
    margin-bottom: 0;
  }
  &:hover {
    .number {
      background-color: var(--pinkColor);
      color: var(--whiteColor);
    }
    h3 {
      color: var(--pinkColor);
    }
  }
}
.involved-card {
  margin-bottom: 30px;
  text-align: center;
  .icon {
    display: block;
    margin-bottom: 10px;
    i {
      font-size: 35px;
      color: var(--lightblueColor);
      line-height: 1;
    }
  }
  h3 {
    color: var(--titleColor);
    margin-bottom: 20px;
    display: block;
  }
  .social-link {
    list-style-type: none;
    margin: 0;
    padding: 7px 12px;
    background-color: var(--pinkColor);
    border-radius: 25px;
    display: inline-block;
    li {
      display: inline-block;
      margin-right: 5px;
      &:last-child {
        margin-right: 0;
      }
      a {
        display: inline-block;
        i {
          width: 32px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          font-size: 16px;
          color: var(--pinkColor);
          background-color: var(--whiteColor);
          transition: var(--transition);
          display: inline-block;
          border-radius: 50px;
        }
        &:hover {
          i {
            background-color: var(--lightblueColor);
            color: var(--whiteColor);
          }
        }
      }
    }
  }
}
.involved-area-two {
  background-color: #0a1831;
  .section-title {
    h2 {
      color: var(--whiteColor);
    }
  }
  .involved-card {
    h3 {
      color: var(--whiteColor);
    }
  }
}
.categories-title {
  margin-bottom: 30px;
  h3 {
    font-size: 24px;
    color: var(--titleColor);
    span {
      color: var(--bodyColor);
      font-size: 17px;
    }
  }
}
/*=================================
Trending Area 
====================================*/
.trending-slider {
  position: relative;
  .owl-nav {
    margin-top: 0;
    .owl-prev {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -30px;
      font-size: 24px !important;
      transition: 0.7s;
      text-align: center;
      width: 45px;
      height: 45px;
      line-height: 45px !important;
      border-radius: 50px !important;
      background-color: var(--whiteColor) !important;
      color: var(--primary-text-color) !important;
      transition: var(--transition);
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
      &:hover {
        color: var(--whiteColor) !important;
        background-color: var(--pinkColor) !important;
      }
    }
    .owl-next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -30px;
      font-size: 24px !important;
      transition: 0.7s;
      text-align: center;
      width: 45px;
      height: 45px;
      line-height: 45px !important;
      border-radius: 50px !important;
      background-color: var(--whiteColor) !important;
      color: var(--primary-text-color) !important;
      transition: var(--transition);
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
      &:hover {
        color: var(--whiteColor) !important;
        background-color: var(--pinkColor) !important;
      }
    }
  }
  .trending-item {
    margin-left: 10px;
    margin-right: 10px;
  }
  .trending-card {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.trending-area-bg {
  background-color: #f6f6f6;
}
.trending-item {
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  border-radius: 15px;

  .trending-img {
    position: relative;
    z-index: 1;

    img {
      border-radius: 15px;
      width: 100%;
      height: 388px;
      object-fit: cover;
    }

    .default-btn {
      top: 50%;
      left: 50%;
      opacity: 0;
      margin-top: 15px;
      visibility: hidden;
      position: absolute;
      transition: var(--transition);
      transform: translateY(-50%) translateX(-50%);
    }
    .trending-user {
      position: absolute;
      z-index: 1;
      top: 25px;
      left: 20px;
      background-color: var(--whiteColor);
      padding: 7px 12px;
      display: inline-block;
      border-radius: 50px;

      .trending-user-option {
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          border-radius: 50%;
          margin-right: 10px;
          width: 30px !important;
          height: 30px !important;
        }
        span {
          padding-top: 3px;
          font-size: 14px;
          color: var(--titleColor);
          font-weight: 600;
          transition: var(--transition);
          line-height: 1;
        }
      }
      &:hover {
        .trending-user-option {
          span {
            color: var(--pinkColor);
          }
        }
      }
    }
    .trending-title {
      position: absolute;
      z-index: 1;
      bottom: 20px;
      left: 20px;
      right: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 15px 12px;

      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(250, 250, 250, 0.35);
        backdrop-filter: blur(25px);
        border-radius: 5px;
      }
      span {
        font-size: 16px;
        color: var(--whiteColor);
        font-weight: 500;
      }
      h3 {
        font-size: 16px;
        color: var(--whiteColor);
        font-weight: 500;
        margin-bottom: 0;
      }
    }
  }
  .content {
    text-align: center;
    padding: 20px;
    background-color: var(--whiteColor);
    border-radius: 0 0 5px 5px;

    h3 {
      margin-bottom: 7px;

      a {
        color: var(--titleColor);
      }
    }
    span {
      font-size: 16px;
      color: var(--bodyColor);

      i {
        font-size: 18px;
        color: var(--pinkColor);
        position: relative;
        top: 3px;
        margin-right: 3px;
      }
    }
  }
  &:hover {
    .content {
      h3 {
        a {
          color: var(--pinkColor);
        }
      }
    }
    .trending-img {
      .default-btn {
        opacity: 1;
        margin-top: 0;
        visibility: visible;
      }
    }
  }
}

.trending-card {
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  border-radius: 15px;

  .trending-card-img {
    position: relative;
    z-index: 1;

    a {
      display: block;

      img {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      }
    }
    span {
      position: absolute;
      top: 20px;
      right: 20px;
      display: inline-block;
      padding: 8px 22px 6px;
      font-size: 16px;
      color: var(--bodyColor);
      background-color: #e5e5e5;
      border: 1px solid var(--whiteColor);
      border-radius: 15px;

      i {
        position: relative;
        top: 3px;
        margin-right: 3px;
        color: var(--pinkColor);
      }
    }
    .default-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: 15px;
      transition: var(--transition);
      opacity: 0;
      visibility: hidden;
      transform: translateY(-50%) translateX(-50%);
    }
  }
  .content {
    padding: 20px 20px 25px;

    h3 {
      margin-bottom: 15px;

      a {
        color: var(--titleColor);
      }
    }
    .trending-user-option {
      display: flex;
      align-items: center;
      position: relative;

      i {
        position: absolute;
        bottom: 5px;
        left: 25px;
        text-align: center;
        width: 18px;
        height: 18px;
        line-height: 18px;
        font-size: 12px;
        border-radius: 50px;
        background-color: var(--lightblueColor);
        color: var(--whiteColor);
      }
      img {
        border-radius: 50%;
        margin-right: 20px;
        width: 35px !important;
        height: 35px !important;
      }
      span {
        font-size: 14px;
        color: var(--titleColor);
        font-weight: 600;
        transition: var(--transition);
        line-height: 1;
      }
    }
    .trending-title {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      background-color: #e5e5e5;

      span {
        font-size: 16px;
        color: var(--titleColor);
        font-weight: 500;
      }
      h4 {
        font-size: 16px;
        color: var(--titleColor);
        font-weight: 500;
        margin-bottom: 0;
      }
    }
  }
  &:hover {
    .content {
      h3 {
        a {
          color: var(--pinkColor);
        }
      }
    }
    .trending-card-img {
      .default-btn {
        opacity: 1;
        margin-top: 0;
        visibility: visible;
      }
    }
  }
}

.trending-area-bg-two {
  background-color: #0a1831 !important;
  .section-title {
    h2 {
      color: var(--whiteColor) !important;
    }
  }
}
/*=================================
Trending Area End
====================================*/

/*=================================
Help Center Page Css
====================================*/
.help-widget-form {
  position: relative;
  margin-top: -25px;
  padding: 0 20px;
  z-index: 555;
  .search-form {
    position: relative;
    margin: 0 auto;
    border-radius: 0;
    border: none;
    max-width: 850px;
    border-radius: 15px;
    .form-control {
      background-color: #f6f6f6;
      border-radius: 15px;
      border: none;
      height: 50px;
      line-height: 50px;
      font-size: 15px;
      margin: 0;
      border: none;
      padding: 0 15px;
      color: var(--bodyColor);
      &::-webkit-input-placeholder,
      &:-ms-input-placeholder,
      &::-ms-input-placeholder,
      &::placeholder {
        color: var(--bodyColor);
      }
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    button {
      position: absolute;
      top: 0;
      right: 0;
      border: 0;
      background-color: var(--pinkColor);
      color: var(--whiteColor);
      height: 50px;
      line-height: 50px;
      font-size: 20px;
      padding: 0 15px;
      transition: var(--transition);
      border-radius: 15px;
      &:hover {
        background: var(--lightblueColor);
      }
    }
  }
}

.help-center-card {
  background-color: var(--whiteColor);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  padding: 30px 25px;
  margin-bottom: 30px;
  border-radius: 5px;
  h3 {
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 0;
    max-width: 340px;
  }
}

.promoted-int-content {
  margin-bottom: 30px;
  .promoted {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    .promoted-item {
      display: block;
      margin-bottom: 5px;
      border: none;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .promoted-title {
      padding: 12px 0 9px 40px;
      color: var(--titleColor);
      text-decoration: none;
      position: relative;
      font-family: var(--headerFonts);
      display: block;
      font-size: 17px;
      border-radius: 0;
      border: none;
      i {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 15px;
        transition: 0.5s;
        width: 28px;
        height: 28px;
        color: var(--whiteColor);
        text-align: center;
        line-height: 28px;
        background-color: var(--pinkColor);
        border-radius: 50px;
      }
      &.active {
        color: var(--pinkColor);
        i {
          color: var(--whiteColor);
          background-color: var(--lightblueColor);
          line-height: 30px;
          &::before {
            content: "\ea4e";
          }
        }
      }
    }
    .promoted-content {
      display: none;
      position: relative;
      margin-top: 0;
      p {
        margin-top: 5px;
        font-size: 15px;
        margin-bottom: 3px;
        background-color: transparent;
        color: var(--bodyColor);
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 0;
        padding-left: 40px;
        border-radius: 0;
      }
      &.show {
        display: block;
      }
    }
  }
}
/*=================================
Help Center Page Css End
====================================*/

/*=================================
Wallet Page Css
====================================*/
.wallet-area {
  .section-title {
    p {
      max-width: 420px;
      a {
        color: var(--lightblueColor);
        &:hover {
          color: var(--pinkColor);
        }
      }
    }
  }
}

.wallet-card {
  background-color: var(--whiteColor);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  padding: 30px 20px;
  position: relative;
  border-radius: 15px;
  margin-bottom: 30px;
  transition: var(--transition);
  img {
    margin-bottom: 20px;
  }
  h3 {
    margin-bottom: 15px;
    transition: var(--transition);
    a {
      color: var(--transition);
      transition: var(--transition);
    }
  }
  p {
    margin-bottom: 0;
  }
  .top-btn {
    position: absolute;
    top: -15px;
    right: 20px;
    padding: 10px 18px 7px;
    color: var(--whiteColor);
    background-color: var(--titleColor);
    font-size: 14px;
    display: inline-block;
    border-radius: 15px;
    transition: var(--transition);
  }
  &:hover {
    transform: translateY(-10px);
    h3 {
      a {
        color: var(--pinkColor);
      }
    }
    .top-btn {
      background-color: var(--pinkColor);
    }
  }
}
.wallet-text {
  margin-bottom: 30px;
  p {
    margin-bottom: 0;
    max-width: 420px;
  }
}
/*=================================
Wallet Page Css End
====================================*/

/*=================================
Item Details Area
====================================*/
.item-details-img {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  img {
    border-radius: 1em;
    width: 100%;
  }
  span {
    position: absolute;
    top: 20px;
    right: 20px;
    display: inline-block;
    padding: 8px 16px 6px;
    font-size: 14px;
    color: var(--bodyColor);
    background-color: #e5e5e5;
    border: 1px solid var(--whiteColor);
    border-radius: 15px;
    i {
      position: relative;
      top: 3px;
      margin-right: 3px;
      color: var(--pinkColor);
    }
  }
}

.item-details-content {
  padding: 35px 20px;
  background-color: #f6f6f6;
  margin-bottom: 30px;
  h3 {
    margin-bottom: 20px;
  }
  .item-details-into {
    height: 325px;
    overflow-y: scroll;
    padding-right: 20px;
    /* width */
    &::-webkit-scrollbar {
      width: 7px;
      background: transparent;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--scroll-bar-color);
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: var(--scroll-bar-color);
    }
  }

  .item-details-card {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    background-color: var(--whiteColor);
    padding: 20px;
    position: relative;
    .item-details-card-img {
      max-width: 55px;
      position: relative;
      margin-right: 20px;
      img {
        border: 2px solid var(--pinkColor);
        border-radius: 10px;
      }
      i {
        position: absolute;
        right: -10px;
        bottom: 10px;
        width: 22px;
        height: 22px;
        line-height: 22px;
        background-color: var(--lightblueColor);
        color: var(--whiteColor);
        font-size: 13px;
        border-radius: 50px;
        text-align: center;
      }
    }
    .item-details-card-content {
      h3 {
        font-size: 16px;
        margin-bottom: 7px;
        color: #666666;
        b {
          color: var(--titleColor);
        }
      }
      span {
        color: var(--pinkColor);
      }
    }
    .work-hours {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      font-size: 16px;
      color: #666666;
    }
  }
}
.item-details-dsce {
  .section-title {
    margin-bottom: 30px;
  }
}
.item-details-user {
  margin-bottom: 30px;
  h3 {
    margin-bottom: 15px;
    font-size: 16px;
  }
  .content {
    display: flex;
    align-items: center;
    .images {
      max-width: 50px;
      position: relative;
      margin-right: 20px;
      img {
        border: 1px solid var(--pinkColor);
        border-radius: 10px;
        max-width: 45px;
      }
      i {
        position: absolute;
        right: -10px;
        bottom: 10px;
        width: 22px;
        height: 22px;
        line-height: 22px;
        background-color: var(--lightblueColor);
        color: var(--whiteColor);
        font-size: 13px;
        border-radius: 50px;
        text-align: center;
      }
    }
    span {
      color: var(--pinkColor);
      margin-bottom: 0;
    }
  }
}

.item-details-price {
  margin-bottom: 20px;
  .item-details-title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    h3 {
      margin-bottom: 0;
      font-size: 20px;
    }
    p {
      margin-bottom: 0;
      margin-left: 70px;
      margin-right: 70px;
      color: var(--pinkColor);
    }
    span {
      margin-bottom: 0;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      display: block;
      margin-bottom: 10px;
      font-size: 16px;
      color: var(--bodyColor);
      position: relative;
      width: 100%;
      &:last-child {
        margin-bottom: 0;
      }
      b {
        font-weight: 500;
        color: var(--lightblueColor);
        position: absolute;
        top: 0;
        left: 170px;
      }
    }
  }
}

.item-details-user-item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  .images {
    max-width: 70px;
    position: relative;
    margin-right: 20px;
    img {
      border-radius: 50%;
      max-width: 65px;
    }
    i {
      position: absolute;
      right: -10px;
      bottom: 10px;
      width: 22px;
      height: 22px;
      line-height: 22px;
      background-color: var(--lightblueColor);
      color: var(--whiteColor);
      font-size: 13px;
      border-radius: 50px;
      text-align: center;
    }
  }
  .content {
    h3 {
      margin-bottom: 5px;
    }
    span {
      color: var(--bodyColor);
      margin-bottom: 0;
    }
  }
}

.item-details-in-content {
  padding: 25px 30px 22px;
  background-color: #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
  margin-bottom: 20px;
  .item-left {
    span {
      font-size: 14px;
      color: var(--bodyColor);
      margin-bottom: 7px;
      display: block;
      font-weight: 400;
    }
    .timer-text {
      font-size: 20px;
      color: var(--titleColor);
      margin-bottom: 0;
      font-weight: 600;
      font-family: var(--bodyFonts);
    }
  }
  .item-right {
    h3 {
      font-size: 14px;
      color: var(--bodyColor);
      margin-bottom: 7px;
      display: block;
      font-weight: 400;
    }
    .item-right-eth {
      font-size: 20px;
      color: var(--titleColor);
      margin-bottom: 0;
      font-weight: 600;
      font-family: var(--bodyFonts);
    }
  }
}

.item-details-btn {
  margin-bottom: 20px;
  .default-btn {
    width: 100%;
    text-align: center;
    &::before {
      width: 950px;
    }
  }
}

.item-details-user-area {
  padding: 35px 20px;
  background-color: #f6f6f6;
  margin-bottom: 30px;
  h3 {
    margin-bottom: 20px;
  }
  .item-details-user-into {
    height: 325px;
    overflow-y: scroll;
    padding-right: 20px;
    /* width */
    &::-webkit-scrollbar {
      width: 7px;
      background: transparent;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--scroll-bar-color);
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: var(--scroll-bar-color);
    }
  }

  .item-details-user-card {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    background-color: var(--whiteColor);
    padding: 20px;
    position: relative;
    .item-details-card-img {
      position: relative;
      margin-right: 20px;
      img {
        border: 2px solid var(--pinkColor);
        border-radius: 10px;
        max-width: 50px;
      }
    }
    .item-details-card-content {
      h3 {
        font-size: 16px;
        margin-bottom: 7px;
        color: #666666;
        b {
          color: var(--titleColor);
        }
      }
      span {
        color: #666666;
        font-size: 16px;
        b {
          color: var(--titleColor);
        }
      }
    }
    .item-details-card-right {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      font-size: 16px;
      color: #666666;
      h3 {
        font-size: 16px;
        margin-bottom: 7px;
        color: #666666;
      }
      span {
        color: #666666;
        font-size: 16px;
      }
    }
  }
}
/*=================================
Item Details Area End
====================================*/

/*=================================
Collection Form Area
====================================*/
.collection-form-area {
  .section-title {
    margin-bottom: 30px;
  }
}
.collection-form {
  // padding: 35px 25px;
  margin-bottom: 30px;
  // background-color: var(--whiteColor);
  background: transparent;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  .form-group {
    margin-bottom: 20px;
    position: relative;
    label {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 600;
      color: var(--titleColor);
    }
    &.checkbox-option {
      position: relative;
      #chb2 {
        position: absolute;
        top: 6px;
        left: 0;
      }
      p {
        padding-left: 25px;
        font-size: 15px;
        color: var(--titleColor);
        a {
          color: var(--pinkColor);
          &:hover {
            color: var(--titleColor);
          }
        }
      }
    }
    .form-control {
      height: 50px;
      color: var(--primary-text-color);
      border: none;
      background-color: var(--color-shade-4);
      font-size: 15px;
      padding: 15px 20px;
      width: 100%;
      border-radius: 0.5rem;
      border: 2px solid transparent;

      &.is-invalid {
        border-color: var(--danger-color);
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
      &::-webkit-input-placeholder,
      &:-ms-input-placeholder,
      &::-ms-input-placeholder,
      &::placeholder {
        color: var(--bodyColor);
      }
    }
    textarea.form-control {
      height: auto;
    }
  }
  .default-btn {
    border: 0;
    outline: none;
    border-radius: 20px;
  }
}
.profile-outer {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
  h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  p {
    word-break: break-word;
  }

  .profileButton {
    position: relative;

    .video-change {
      background-color: none;
      border: none;
      outline: none;
      color: var(--primary-text-color);
      margin-bottom: 0.5rem;

      &:hover,
      &:focus,
      &:active {
        background-color: none;
        border: none;
        outline: none;
        color: var(--primary-text-color);
      }

      &:hover {
        cursor: pointer;
      }
    }

    &:hover {
      .profileButton-button {
        display: grid;
      }
    }

    .profileButton-input {
      opacity: 0;
      position: absolute;
      overflow: hidden;
      z-index: -1;
      pointer-events: none;
      height: 50px;
      line-height: 50px;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 15px;
      width: 100%;
      display: none;
      transition: var(--transition);
    }
    .profileButton-button {
      height: 50px;
      line-height: 50px;
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;
      cursor: pointer;
      border-radius: 15px;
      transition: var(--transition);
      margin: 0;
      color: var(--bodyColor);
      border: none;
      background-color: #f6f6f6;
      display: none;

      &:hover {
        border: 1px solid var(--pinkColor);
      }
      &:before {
        display: none;
      }

      & svg {
        width: 2em;
        height: 2em;
      }
    }
  }
}

.preview-box {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
  h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .previewButton {
    position: relative;
    border: 3px dashed rgb(204, 204, 204);
    border-radius: 15px;
    .previewButton-input {
      opacity: 0;
      position: absolute;
      overflow: hidden;
      z-index: -1;
      pointer-events: none;
      width: 100%;
      height: 170px;
      font-size: 15px;
      text-align: center;
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;
      display: none;
      transition: var(--transition);
    }
    .previewButton-button {
      height: 50px;
      height: 170px;
      text-align: center;
      padding: 1em;
      width: 100%;
      cursor: pointer;
      border-radius: 15px;
      transition: var(--transition);
      margin: 0;
      color: var(--bodyColor);
      font-size: 16px;
      border: none;
      background-color: #f6f6f6;
      display: grid;
      place-items: center;
      &:before {
        display: none;
      }

      & svg {
        width: 2em;
        height: 2em;
      }
    }
  }
}

.collection-category {
  margin-bottom: 10px;
  h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      padding: 0;
      margin: 0 15px 10px 0;
      transition: 0.7s;
      color: var(--lightblueColor);
      font-weight: 600;
      background-color: var(--color-shade-4);
      border-radius: 0.5em;
      p {
        padding: 12px 25px 9px;
        color: var(--lightblueColor);
        cursor: pointer;
      }

      &.active {
        background-color: var(--lightblueColor);
        transform: translateY(-2px);
        p {
          color: var(--whiteColor);
        }
      }

      &:hover {
        background-color: var(--lightblueColor);
        p {
          color: var(--whiteColor);
        }
      }
    }
  }
}

.checkbox-method-area {
  margin-top: 10px;
  margin-bottom: 20px;
  .checkbox-method {

    p{
      line-height: 2rem;
    }

    p [type="radio"]:checked {
      display: none;
    }
    p [type="radio"]:checked + label {
      padding-left: 24px;
      cursor: pointer;
      display: block;
      font-weight: 600;
      color: var(--titleColor);
      position: relative;
      margin-bottom: 15px;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 4px;
        width: 1.5rem;
        height: 1.5rem;
        border: 1px solid var(--primary-boder-light);
        border-radius: 50%;
        background: var(--color-shade-4);
      }
      &::after {
        content: "";
        width: 1.5rem;
        height: 1.5rem;
        background-color: var(--color-shade-4);
        position: absolute;
        top: 7px;
        left: 3px;
        border-radius: 50%;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        opacity: 1;
        visibility: visible;
        transform: scale(1);
      }
    }
    p [type="radio"]:not(:checked) {
      display: none;
    }
    p [type="radio"]:not(:checked) + label {
      padding-left: 2rem;
      cursor: pointer;
      display: block;
      font-weight: 600;
      color: #172541;
      position: relative;
      margin-bottom: 15px;
      font-size: 0.8rem;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 4px;
        width: 1.5rem;
        height: 1.5rem;
        border: 2px solid var(--primary-boder-light);
        border-radius: 50%;
        background: transparent;
      }
      &::after {
        content: "";
        width: 9px;
        height: 9px;
        background: var(--pinkColor);
        position: absolute;
        top: 7px;
        left: 3px;
        border-radius: 50%;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        opacity: 0;
        visibility: hidden;
        transform: scale(0);
      }
    }
  }
}
/*=================================
Collection Form Area End
====================================*/

/*=================================
Top Seller Area
====================================*/
.top-sellers-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--primary-border-color);

  .number {
    color: var(--titleColor);
    font-weight: 600;
    margin-bottom: 0;
    margin-right: 10px;
  }

  .top-sellers-img {
    position: relative;
    margin-right: 25px;
    img {
      border-radius: 50%;
      max-width: 50px;
      width: 50px;
      height: 50px;
      object-fit: cover;
    }

    i {
      position: absolute;
      bottom: 5px;
      right: -10px;
      text-align: center;
      width: 22px;
      height: 22px;
      line-height: 22px;
      font-size: 14px;
      border-radius: 50px;
      background-color: var(--lightblueColor);
      color: var(--whiteColor);
    }
  }

  .content {
    align-self: stretch;
    flex: 1 1 auto;
    flex-flow: column;
    -webkit-box-pack: center;
    justify-content: center;
    overflow: hidden;
    font-size: 16px;
    align-items: flex-start;
    margin-right: 15px;

    h5 {
      // width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 250px;
    }
    span {
      font-size: 14px;
    }
  }
  &:hover {
    h3 {
      a {
        color: var(--pinkColor);
      }
    }
  }
}
/*=================================
Top Seller Area End
====================================*/

/*=================================
Activity Area
====================================*/
.activity-card {
  background-color: var(--whiteColor);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  padding: 20px 20px;
  display: flex;
  align-items: center;
  border-radius: 15px;
  margin-bottom: 30px;
  position: relative;
  .activity-img {
    margin-right: 20px;
    transform: var(--transition);
    img {
      border-radius: 15px;
    }
  }
  .activity-content {
    p {
      font-size: 15px;
      color: var(--bodyFonts);
      margin-bottom: 15px;
      i {
        color: var(--lightblueColor);
        font-size: 17px;
        position: relative;
        top: 3px;
        margin-right: 3px;
      }
    }
    span {
      font-size: 15px;
      color: var(--bodyFonts);
      margin-bottom: 0;
      i {
        color: var(--lightblueColor);
        font-size: 17px;
        position: relative;
        top: 3px;
        margin-right: 3px;
      }
    }
  }
  .activity-into {
    margin-left: 130px;
    margin-right: 230px;
    h3 {
      margin-bottom: 15px;
      transition: var(--transition);
    }
    span {
      font-size: 15px;
      color: var(--bodyFonts);
      margin-bottom: 0;
    }
  }
  .activity-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    i {
      width: 35px;
      height: 35px;
      line-height: 35px;
      border-radius: 50px;
      text-align: center;
      font-size: 16px;
      background-color: rgba(#f14d5d, 0.15%);
      color: var(--pinkColor);
      display: inline-block;
      transition: var(--transition);
    }
    &:hover {
      i {
        color: var(--whiteColor);
        background-color: var(--pinkColor);
      }
    }
  }
  &:hover {
    .activity-into {
      h3 {
        color: var(--pinkColor);
      }
    }
  }
}
/*=================================
Activity Area End
====================================*/

/*=================================
Auctions Area
====================================*/
.auctions-slider {
  position: relative;
  .owl-dots {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    line-height: 0;
    .owl-dot {
      span {
        background-color: #c4c4c4 !important;
        transition: 0.7s;
        margin: 0 7px;
        width: 7px !important;
        height: 7px !important;
        position: relative;
        z-index: 1;
        border-radius: 50px;
      }
      &.active {
        span {
          background-color: var(--pinkColor) !important;
          width: 35px !important;
          height: 7px !important;
        }
      }
      &:hover {
        span {
          background-color: var(--pinkColor) !important;
          width: 35px !important;
          height: 7px !important;
        }
      }
    }
  }
  .auction-item {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.auction-item {
  margin-bottom: 30px;
  border-radius: 5px;
  background-color: var(--whiteColor);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  transition: var(--transition);

  .auction-item-img {
    position: relative;

    img {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    .auction-item-content {
      position: absolute;
      padding: 15px;
      bottom: 20px;
      left: 20px;
      right: 20px;
      z-index: 1;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(250, 250, 250, 0.35);
        backdrop-filter: blur(25px);
        border-radius: 5px;
        border: 1px solid rgba(250, 250, 250, 0.5);
      }
      .auction-left {
        span {
          font-size: 15px;
          color: var(--whiteColor);
          margin-bottom: 7px;
          display: block;
          font-weight: 400;
        }
        h3 {
          font-size: 20px;
          color: var(--whiteColor);
          margin-bottom: 0;
        }
      }
      .auction-right {
        h3 {
          font-size: 15px;
          color: var(--whiteColor);
          margin-bottom: 7px;
          display: block;
          font-weight: 400;
        }
        .auction-text {
          font-size: 20px;
          color: var(--whiteColor);
          margin-bottom: 0;
          font-weight: 600;
          font-family: var(--bodyFonts);
        }
      }
    }
  }
  .content {
    padding: 20px 15px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;

    .auction-item-list {
      position: relative;
      padding-left: 70px;

      .auction-item-list-img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: var(--transition);
        left: 0;

        img {
          border-radius: 50%;
        }
        i {
          position: absolute;
          bottom: 5px;
          right: -10px;
          text-align: center;
          width: 22px;
          height: 22px;
          line-height: 22px;
          font-size: 14px;
          border-radius: 50px;
          background-color: var(--lightblueColor);
          color: var(--whiteColor);
        }
      }
      h3 {
        font-size: 18px;
        margin-bottom: 3px;

        a {
          color: var(--titleColor);
          transition: var(--transition);
        }
      }
      span {
        font-size: 14px;
        color: var(--bodyColor);

        a {
          color: var(--bodyColor);
          transition: var(--transition);

          &:hover {
            color: var(--pinkColor);
          }
        }
      }
      &:hover {
        h3 {
          a {
            color: var(--pinkColor);
          }
        }
      }
    }
    .auction-item-btn {
      width: 32px;
      height: 32px;
      line-height: 32px;
      font-size: 18px;
      border-radius: 50px;
      background-color: var(--pinkColor);
      color: var(--whiteColor);
      text-align: center;
      display: inline-block;
      transition: var(--transition);

      &:hover {
        background-color: var(--lightblueColor);
      }
    }
    .default-btn {
      transition: var(--transition);
      position: absolute;
      right: 10px;
      top: 50%;
      opacity: 0;
      visibility: hidden;
      transform: translateY(-50%);
      padding: 10px 20px 7px;
    }
    &:hover {
      .auction-item-list {
        h3 {
          a {
            color: var(--pinkColor);
          }
        }
        .auction-item-list-img {
          transform: translateY(-52%);
        }
      }
    }
  }
  &:hover {
    transform: translateY(-10px);

    .content {
      .auction-item-list {
        h3 {
          a {
            color: var(--pinkColor);
          }
        }
      }
      .default-btn {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.auctions-slider-two {
  position: relative;
  .owl-dots {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    line-height: 0;
    .owl-dot {
      span {
        background-color: #c4c4c4 !important;
        transition: 0.7s;
        margin: 0 7px;
        width: 7px !important;
        height: 7px !important;
        position: relative;
        z-index: 1;
        border-radius: 50px;
      }
      &.active {
        span {
          background-color: var(--pinkColor) !important;
          width: 35px !important;
          height: 7px !important;
        }
      }
      &:hover {
        span {
          background-color: var(--pinkColor) !important;
          width: 35px !important;
          height: 7px !important;
        }
      }
    }
  }
  .auction-card {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.auction-card {
  position: relative;
  z-index: 1;
  padding: 0 20px 25px;
  margin-bottom: 30px;
  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 70%;
    background-color: var(--whiteColor);
    transition: var(--transition);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
  }
  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 0%;
    opacity: 0;
    transition: var(--transition);
    background-color: #e5e5e5;
    border-radius: 5px;
  }
  .auction-card-img {
    position: relative;
    z-index: 1;
    a {
      display: block;
      img {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
    .auction-card-user {
      position: absolute;
      z-index: 1;
      top: 25px;
      left: 20px;
      background-color: var(--whiteColor);
      padding: 7px 12px;
      display: inline-block;
      border-radius: 50px;
      .auction-card-user-option {
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
          border-radius: 50%;
          margin-right: 10px;
          width: 30px !important;
          height: 30px !important;
        }
        span {
          font-size: 14px;
          color: var(--titleColor);
          font-weight: 600;
          transition: var(--transition);
          line-height: 1;
          padding-top: 5px;
        }
      }
      &:hover {
        .auction-card-user-option {
          span {
            color: var(--pinkColor);
          }
        }
      }
    }
    .auction-card-into {
      position: absolute;
      z-index: 1;
      bottom: 20px;
      left: 20px;
      right: 20px;
      text-align: center;
      padding: 15px;
      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(250, 250, 250, 0.35);
        backdrop-filter: blur(25px);
        border-radius: 5px;
      }
      h3 {
        font-size: 16px;
        color: var(--whiteColor);
        font-weight: 500;
        margin-bottom: 0;
      }
      .auction-timer {
        margin-top: 10px;
        .auction-title {
          display: inline-block;
          color: var(--whiteColor);
          position: relative;
          margin: 0 3px;
          font-size: 20;
          font-weight: 600;
          &:last-child {
            margin-right: 0;
          }
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
  .content {
    position: relative;
    h3 {
      margin-top: 25px;
      margin-bottom: 20px;
      a {
        color: var(--titleColor);
        transition: var(--transition);
      }
    }
    p {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      padding: 5px 20px 3px;
      font-size: 16px;
      color: var(--bodyColor);
      background-color: #e5e5e5;
      border-radius: 50px;
      i {
        position: relative;
        top: 3px;
        margin-right: 3px;
        color: var(--pinkColor);
      }
    }
    .auction-card-content {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      .card-left {
        margin-right: 70px;
        span {
          font-size: 15px;
          color: var(--titleColor);
          margin-bottom: 10px;
          display: block;
          font-weight: 400;
        }
        h4 {
          font-size: 20px;
          color: var(--titleColor);
          margin-bottom: 0;
          margin-top: 0;
        }
      }
      .card-right {
        span {
          font-size: 15px;
          color: var(--titleColor);
          margin-bottom: 10px;
          display: block;
          font-weight: 400;
          margin-top: 0;
        }
        h4 {
          font-size: 20px;
          color: var(--titleColor);
          margin-bottom: 0;
          font-weight: 600;
          font-family: var(--bodyFonts);
        }
      }
    }
    .place-btn {
      padding: 10px 25px 8px;
      text-align: center;
      color: var(--whiteColor) !important;
      font-size: 15px;
      font-weight: 600;
      transition: var(--transition);
      display: inline-block;
      align-items: center;
      justify-content: center;
      position: relative;
      border-radius: 50px;
      z-index: 0;
      background: var(--pinkColor);
      overflow: hidden;
      white-space: nowrap;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        width: 550px;
        height: 550px;
        margin: auto;
        background: var(--lightblueColor);
        border-radius: 50px;
        z-index: -1;
        transform-origin: top center;
        transform: translateX(-50%) translateY(-5%) scale(0.4);
        transition: transform 0.9s;
      }
      &:hover {
        color: var(--whiteColor) !important;
        &:before {
          transition: transform 1s;
          transform: translateX(-45%) translateY(0) scale(1);
          transform-origin: bottom center;
        }
      }
    }
  }
  &:hover {
    &::before {
      height: 0;
      opacity: 0;
    }
    &::after {
      height: 70%;
      opacity: 1;
    }
    .content {
      h3 {
        a {
          color: var(--pinkColor);
        }
      }
      p {
        background-color: rgba(250, 250, 250, 0.4);
      }
    }
  }
}
/*=================================
Auctions Area End
====================================*/

/*=================================
Featured Area
====================================*/
.featured-area {
  background-color: #f6f6f6;
}
.featured-tab-area {
  .tabs {
    margin: 0;
    padding: 0;
    list-style: none;
    float: right;
    li {
      display: inline-block;
      line-height: initial;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
      a {
        display: inline-block;
        position: relative;
        color: var(--titleColor);
        padding: 13px 26px 10px;
        background-color: #e5e5e5;
        border-radius: 5px;
      }
      &.active a {
        background-color: var(--pinkColor);
        color: var(--whiteColor);
      }
      &.current a {
        background-color: var(--pinkColor);
        color: var(--whiteColor);
      }
    }
  }
}
.featured-tab-area-ml {
  .tabs {
    margin-top: 0 !important;
    float: left;
  }
}
.tab {
  .tabs_item {
    display: none;
    &:first-child {
      display: block;
    }
  }
}
.featured-item {
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  border-radius: 5px;

  .featured-item-img {
    position: relative;
    z-index: 1;

    a {
      display: block;

      img {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
    .featured-user {
      position: absolute;
      z-index: 1;
      top: 25px;
      left: 20px;
      background-color: var(--whiteColor);
      padding: 7px 15px 7px 10px;
      display: inline-block;
      border-radius: 50px;

      .featured-user-option {
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          border-radius: 50%;
          margin-right: 10px;
          width: 30px !important;
          height: 30px !important;
        }
        span {
          font-size: 14px;
          color: var(--titleColor);
          font-weight: 600;
          transition: var(--transition);
          line-height: 1;
          padding-top: 5px;
        }
      }
      &:hover {
        .featured-user-option {
          span {
            color: var(--pinkColor);
          }
        }
      }
    }
    .featured-item-clock {
      position: absolute;
      z-index: 1;
      bottom: 20px;
      left: 20px;
      right: 20px;
      padding: 10px 10px 7px;
      width: 90%;
      font-size: 20px;
      color: var(--whiteColor);
      text-align: center;

      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.35);
        backdrop-filter: blur(25px);
        border-radius: 5px;
      }
    }
    .default-btn {
      position: absolute;
      transition: var(--transition);
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      left: 50%;
      opacity: 0;
      visibility: hidden;
      margin-top: 15px;
    }
  }
  .content {
    padding: 20px 15px;

    h3 {
      margin-bottom: 10px;

      a {
        color: var(--titleColor);
        transition: var(--transition);
      }
    }
    .content-in {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 12px 10px;
      border-radius: 3px;
      border: 1px solid var(--lightblueColor);

      span {
        font-size: 15px;
        color: var(--titleColor);
        font-weight: 500;
      }
      h4 {
        font-size: 15px;
        color: var(--titleColor);
        font-weight: 500;
        margin-bottom: 0;
      }
    }
    .featured-content-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 0 0;

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        position: relative;
        top: 3px;

        li {
          display: inline-block;
          margin-right: -15px;

          img {
            max-width: 33px;
            border: 4px solid var(--whiteColor);
            border-radius: 50px;
            transition: var(--transition);
          }
          &.title {
            margin-right: 0;
            margin-left: 20px;
            font-size: 14px;
            color: var(--bodyColor);
            font-weight: 500;
            vertical-align: middle;
          }
          &:hover {
            img {
              transform: scale(1.1);
            }
          }
        }
      }
      p {
        font-size: 15px;
        color: var(--bodyColor);

        i {
          font-size: 17px;
          color: var(--pinkColor);
          position: relative;
          top: 3px;
          margin-right: 2px;
        }
      }
    }
  }
  &:hover {
    .content {
      h3 {
        a {
          color: var(--pinkColor);
        }
      }
    }
    .featured-item-img {
      .default-btn {
        opacity: 1;
        margin-top: 0;
        visibility: visible;
      }
    }
  }
}

.featured-card {
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  border-radius: 15px;
  transition: var(--transition);
  margin: 0.5em;

  .featured-card-img {
    position: relative;
    z-index: 1;

    a {
      display: block;

      img {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      }
    }
    .featured-card-clock {
      position: absolute;
      z-index: 1;
      bottom: 20px;
      left: 20px;
      right: 20px;
      padding: 10px 10px 7px;
      width: 90%;
      font-size: 20px;
      color: var(--whiteColor);
      text-align: center;

      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.35);
        backdrop-filter: blur(25px);
        border-radius: 5px;
      }
    }
    p {
      position: absolute;
      top: 20px;
      right: 20px;
      display: inline-block;
      padding: 8px 22px 6px;
      font-size: 16px;
      color: var(--bodyColor);
      background-color: #e5e5e5;
      border: 1px solid var(--whiteColor);
      border-radius: 15px;
      margin-bottom: 0;
      transition: var(--transition);

      i {
        position: relative;
        top: 3px;
        margin-right: 3px;
        color: var(--pinkColor);
      }
    }
    .default-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: 15px;
      transition: var(--transition);
      opacity: 0;
      visibility: hidden;
      transform: translateY(-50%) translateX(-50%);
    }
  }
  .content {
    padding: 20px 15px 25px;

    h3 {
      margin-bottom: 10px;

      a {
        color: var(--titleColor);
        transition: var(--transition);
      }
    }
    .content-in {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 12px 10px;
      border-radius: 3px;
      border: 1px solid var(--lightblueColor);
      margin-bottom: 15px;

      .featured-card-left {
        span {
          font-size: 16px;
          color: var(--titleColor);
          font-weight: 500;
          display: block;
          margin-bottom: 10px;
        }
        h4 {
          font-size: 16px;
          color: var(--titleColor);
          font-weight: 500;
          margin-bottom: 0;
        }
      }
      .featured-content-btn {
        width: 35px;
        height: 35px;
        line-height: 35px;
        font-size: 18px;
        border-radius: 50px;
        background-color: var(--pinkColor);
        color: var(--whiteColor);
        text-align: center;
        display: inline-block;
        transition: var(--transition);

        &:hover {
          background-color: var(--lightblueColor);
          color: var(--whiteColor);
        }
      }
    }
    .featured-user-option {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #e5e5e5;
      padding: 7px 15px 7px 10px;
      display: inline-block;
      border-radius: 50px;

      img {
        border-radius: 50%;
        margin-right: 10px;
        width: 30px !important;
        height: 30px !important;
        border: 2px solid var(--whiteColor);
      }
      span {
        font-size: 14px;
        color: var(--titleColor);
        font-weight: 600;
        transition: var(--transition);
        line-height: 1;
        padding-top: 5px;
      }
      &:hover {
        span {
          color: var(--pinkColor);
        }
      }
    }
  }
  &:hover {
    transform: translateY(-10px);

    .featured-card-img {
      p {
        color: var(--whiteColor);
        background-color: var(--pinkColor);
        i {
          color: var(--whiteColor);
        }
      }
      .default-btn {
        opacity: 1;
        margin-top: 0;
        visibility: visible;
      }
    }
    .content {
      h3 {
        a {
          color: var(--pinkColor);
        }
      }
    }
  }
}
/*=================================
Featured Area End
====================================*/

/*=================================
Testimonial Area
====================================*/
.testimonial-slider {
  position: relative;
  .owl-dots {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    line-height: 0;
    .owl-dot {
      span {
        background-color: #c4c4c4 !important;
        transition: 0.7s;
        margin: 0 7px;
        width: 7px !important;
        height: 7px !important;
        position: relative;
        z-index: 1;
        border-radius: 50px;
      }
      &.active {
        span {
          background-color: var(--pinkColor) !important;
          width: 35px !important;
          height: 7px !important;
        }
      }
      &:hover {
        span {
          background-color: var(--pinkColor) !important;
          width: 35px !important;
          height: 7px !important;
        }
      }
    }
  }
}

.testimonial-item {
  position: relative;
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  padding: 35px 0;
}
.testimonial-img {
  margin-bottom: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  img {
    width: unset !important;
  }
}

.testimonial-content {
  text-align: center;
  img {
    width: unset !important;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  p {
    font-size: 20px;
    max-width: 850px;
    margin-bottom: 20px;
    color: var(--titleColor);
    margin-left: auto;
    margin-right: auto;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      margin-right: 25px;
      position: relative;
      font-size: 16px;
      color: var(--bodyColor);
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: -15px;
        height: 20px;
        width: 1.8px;
        background-color: var(--bodyColor);
      }
      &:last-child {
        margin-right: 0;
        &::before {
          display: none;
        }
      }
    }
  }
  span {
    color: var(--bodyColor);
    margin-bottom: 0;
  }
}

.testimonial-slider-two {
  position: relative;
  .owl-dots {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    position: absolute;
    left: 0;
    bottom: 0;
    line-height: 0;
    .owl-dot {
      span {
        background-color: #c4c4c4 !important;
        transition: 0.7s;
        margin: 0 7px;
        width: 7px !important;
        height: 7px !important;
        position: relative;
        z-index: 1;
        border-radius: 50px;
      }
      &.active {
        span {
          background-color: var(--pinkColor) !important;
          width: 35px !important;
          height: 7px !important;
        }
      }
      &:hover {
        span {
          background-color: var(--pinkColor) !important;
          width: 35px !important;
          height: 7px !important;
        }
      }
    }
  }
}
.testimonial-slider-content {
  margin-bottom: 30px;
  img {
    width: unset !important;
    margin-bottom: 20px;
  }
  p {
    font-size: 20px;
    max-width: 850px;
    margin-bottom: 20px;
    color: var(--titleColor);
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      margin-right: 25px;
      position: relative;
      font-size: 16px;
      color: var(--bodyColor);
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: -15px;
        height: 20px;
        width: 1.8px;
        background-color: var(--bodyColor);
      }
      &:last-child {
        margin-right: 0;
        &::before {
          display: none;
        }
      }
    }
  }
}
.testimonial-slider-img {
  img {
    width: unset !important;
  }
}

.testimonial-area-two {
  background-color: #0a1831;
  .section-title {
    h2 {
      color: var(--whiteColor);
    }
  }
  .testimonial-slider-content {
    p {
      color: var(--whiteColor);
    }
    ul {
      li {
        color: #a8a8a8;
        &::before {
          background-color: #a8a8a8;
        }
      }
    }
  }
}
/*=================================
Testimonial Area End
====================================*/
.team-area {
  background-color: #e5e5e5;
  position: relative;
  z-index: 1;
}
.team-card {
  background-color: var(--whiteColor);
  padding: 30px 25px;
  border-radius: 15px;
  transition: var(--transition);
  margin-bottom: 30px;
  a {
    display: block;
    img {
      border-radius: 15px;
      margin-bottom: 20px;
    }
  }
  h3 {
    margin-bottom: 5px;
    a {
      color: var(--titleColor);
      transition: var(--transition);
    }
  }
  span {
    color: var(--pinkColor);
    display: block;
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 0;
  }
  &:hover {
    transform: translateX(-10px);
    h3 {
      a {
        color: var(--pinkColor);
      }
    }
  }
}
.team-shape {
  position: absolute;
  z-index: -1;
  bottom: 0;
  right: 0;
}

/*=================================
Create Area
====================================*/
.create-img {
  margin-bottom: 30px;
}
.create-card {
  margin-bottom: 30px;
  // background: var(--primary-box-color);
  padding: 1em;
  border-radius: 8px;
  img {
    margin-bottom: 20px;
  }
  h3 {
    margin-bottom: 10px;
    a {
      color: var(--titleColor);
      transition: var(--transition);
    }
  }
  p {
    margin-bottom: 0;
    max-width: unset;
    a {
      color: var(--lightblueColor);
      transition: var(--transition);
      display: inline-block;
      &:hover {
        color: var(--pinkColor);
      }
    }
  }
  &:hover {
    h3 {
      a {
        color: var(--pinkColor);
      }
    }
  }
}
/*=================================
Create Area End
====================================*/

/*=================================
Author Area
====================================*/
.author-area-bg {
  background-color: #0a1831;
}
.author-area-bg2 {
  background-image: url(./images/author/author-bg.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.author-area-bg3 {
  background-color: #e5e5e5;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.author-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
  .section-title {
    h2 {
      color: var(--whiteColor);
    }
  }
}
.author-widget-bg {
  background-color: var(--whiteColor);
}
.author-area-shape {
  position: absolute;
  top: 25%;
  right: 0;
  z-index: -1;
}
.author-area-shape-two {
  position: absolute;
  top: 5%;
  right: 0;
  z-index: -1;
}
.author-card {
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  border-radius: 5px;
  transition: var(--transition);
  a {
    display: block;
    img {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
  .content {
    padding: 0 20px 25px;
    position: relative;
    .content-follow {
      display: inline-block;
      padding: 10px 14px 8px;
      color: var(--whiteColor);
      background-color: var(--pinkColor);
      border-radius: 5px;
      position: absolute;
      top: 5px;
      right: 20px;
      cursor: pointer;
      transition: var(--transition);
      &:hover {
        background-color: var(--lightblueColor);
      }
      .author-social {
        padding-left: 0;
        list-style-type: none;
        position: absolute;
        z-index: 1;
        right: 0;
        bottom: 50px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 0;
        li {
          display: block;
          transform: translateY(30px);
          transition: all 0.3s ease-in-out 0s;
          opacity: 0;
          visibility: hidden;
          margin-bottom: 7px;
          &:last-child {
            margin-bottom: 0;
          }
          a {
            display: block;
            i {
              width: 32px;
              height: 32px;
              line-height: 32px;
              text-align: center;
              font-size: 16px;
              color: var(--whiteColor);
              background-color: var(--lightblueColor);
              transition: var(--transition);
              display: inline-block;
              border-radius: 50px;
            }
            &:hover {
              i {
                background-color: var(--pinkColor);
                color: var(--whiteColor);
              }
            }
          }
          &:nth-child(1) {
            transition-delay: 0.1s;
          }
          &:nth-child(2) {
            transition-delay: 0.2s;
          }
          &:nth-child(3) {
            transition-delay: 0.3s;
          }
          &:nth-child(4) {
            transition-delay: 0.4s;
          }
        }
      }
      &:hover {
        .author-social {
          li {
            transform: translateY(0);
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
    .author-user {
      position: relative;
      margin-top: -30px;
      max-width: 70px;
      margin-bottom: 20px;
      img {
        border-radius: 5px;
        border: 3px solid var(--pinkColor);
        max-width: 64px;
      }
      i {
        position: absolute;
        bottom: 7px;
        right: -5px;
        text-align: center;
        width: 22px;
        height: 22px;
        line-height: 22px;
        font-size: 14px;
        border-radius: 50px;
        background-color: var(--lightblueColor);
        color: var(--whiteColor);
      }
    }
    h3 {
      margin-bottom: 7px;
      a {
        color: var(--titleColor);
        transition: var(--transition);
      }
    }
    span {
      color: var(--pinkColor);
      margin-bottom: 15px;
      display: block;
    }
    p {
      margin-bottom: 0;
    }
    .author-user-list {
      display: flex;
      align-items: center;
      .author-user-img {
        position: relative;
        margin-right: 25px;
        img {
          border-radius: 5px;
        }

        i {
          position: absolute;
          bottom: 5px;
          right: -10px;
          text-align: center;
          width: 22px;
          height: 22px;
          line-height: 22px;
          font-size: 14px;
          border-radius: 50px;
          background-color: var(--lightblueColor);
          color: var(--whiteColor);
        }
      }

      .author-user-content {
        h3 {
          margin-bottom: 7px;
          a {
            color: var(--titleColor);
          }
        }
        span {
          font-size: 14px;
          margin-bottom: 0;
        }
      }
    }

    .author-content {
      padding: 10px 15px;
      background-color: #e5e5e5;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;
      .content-left {
        span {
          font-size: 14px;
          color: var(--bodyColor);
          display: block;
          margin-bottom: 5px;
        }
        h4 {
          font-size: 22px;
          margin-bottom: 0;
          font-weight: 600;
        }
      }
      .content-right {
        display: inline-block;
        padding: 10px 14px 8px;
        color: var(--whiteColor);
        background-color: var(--pinkColor);
        border-radius: 5px;
        position: relative;
        cursor: pointer;
        transition: var(--transition);
        &:hover {
          background-color: var(--lightblueColor);
        }
        .author-social {
          padding-left: 0;
          list-style-type: none;
          position: absolute;
          z-index: 1;
          right: 0;
          bottom: 50px;
          text-align: center;
          margin: 0 auto;
          margin-bottom: 0;
          li {
            display: block;
            transform: translateY(30px);
            transition: all 0.3s ease-in-out 0s;
            opacity: 0;
            visibility: hidden;
            margin-bottom: 7px;
            &:last-child {
              margin-bottom: 0;
            }
            a {
              display: block;
              i {
                width: 32px;
                height: 32px;
                line-height: 32px;
                text-align: center;
                font-size: 16px;
                color: var(--whiteColor);
                background-color: var(--lightblueColor);
                transition: var(--transition);
                display: inline-block;
                border-radius: 50px;
              }
              &:hover {
                i {
                  background-color: var(--pinkColor);
                  color: var(--whiteColor);
                }
              }
            }
            &:nth-child(1) {
              transition-delay: 0.1s;
            }
            &:nth-child(2) {
              transition-delay: 0.2s;
            }
            &:nth-child(3) {
              transition-delay: 0.3s;
            }
            &:nth-child(4) {
              transition-delay: 0.4s;
            }
          }
        }
        &:hover {
          .author-social {
            li {
              transform: translateY(0);
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
  }
  &:hover {
    transform: translateY(-5px);
    .content {
      h3 {
        a {
          color: var(--pinkColor);
        }
      }
    }
  }
}

.author-item {
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  border-radius: 5px;
  transition: var(--transition);
  a {
    display: block;
    img {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
  .content {
    padding: 0 20px 25px;
    .author-user-list {
      display: flex;
      align-items: center;
      margin: 25px 0 20px;
      .author-user-img {
        position: relative;
        margin-right: 25px;
        img {
          border-radius: 5px;
          border: 3px solid var(--pinkColor);
        }

        i {
          position: absolute;
          bottom: 5px;
          right: -10px;
          text-align: center;
          width: 22px;
          height: 22px;
          line-height: 22px;
          font-size: 14px;
          border-radius: 50px;
          background-color: var(--lightblueColor);
          color: var(--whiteColor);
        }
      }

      .author-user-content {
        h3 {
          margin-bottom: 5px;
          a {
            color: var(--titleColor);
          }
        }
        span {
          font-size: 14px;
          margin-bottom: 0;
        }
      }
    }

    .author-content {
      padding: 10px 15px;
      background-color: #e5e5e5;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;
      .content-left {
        span {
          font-size: 14px;
          color: var(--bodyColor);
          display: block;
          margin-bottom: 5px;
        }
        h4 {
          font-size: 22px;
          margin-bottom: 0;
          font-weight: 600;
        }
      }
      .content-right {
        display: inline-block;
        padding: 10px 14px 8px;
        color: var(--whiteColor);
        background-color: var(--pinkColor);
        border-radius: 5px;
        position: relative;
        cursor: pointer;
        transition: var(--transition);
        &:hover {
          background-color: var(--lightblueColor);
        }
        .author-social {
          padding-left: 0;
          list-style-type: none;
          position: absolute;
          z-index: 1;
          right: 0;
          bottom: 50px;
          text-align: center;
          margin: 0 auto;
          margin-bottom: 0;
          li {
            display: block;
            transform: translateY(30px);
            transition: all 0.3s ease-in-out 0s;
            opacity: 0;
            visibility: hidden;
            margin-bottom: 7px;
            &:last-child {
              margin-bottom: 0;
            }
            a {
              display: block;
              i {
                width: 32px;
                height: 32px;
                line-height: 32px;
                text-align: center;
                font-size: 16px;
                color: var(--whiteColor);
                background-color: var(--lightblueColor);
                transition: var(--transition);
                display: inline-block;
                border-radius: 50px;
              }
              &:hover {
                i {
                  background-color: var(--pinkColor);
                  color: var(--whiteColor);
                }
              }
            }
            &:nth-child(1) {
              transition-delay: 0.1s;
            }
            &:nth-child(2) {
              transition-delay: 0.2s;
            }
            &:nth-child(3) {
              transition-delay: 0.3s;
            }
            &:nth-child(4) {
              transition-delay: 0.4s;
            }
          }
        }
        &:hover {
          .author-social {
            li {
              transform: translateY(0);
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
  }
  &:hover {
    transform: translateY(-5px);
    .content {
      h3 {
        a {
          color: var(--pinkColor);
        }
      }
    }
  }
}

.author-profile-sidebar {
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  border-radius: 15px;
  transition: var(--transition);
  padding: 30px 25px;
  position: relative;
  margin-top: -140px;
  .author-user {
    margin-bottom: 20px;
    position: relative;
    max-width: 185px;
    img {
      border-radius: 5px;
      border: 3px solid var(--pinkColor);
      max-width: 180px;
    }
    i {
      position: absolute;
      bottom: 7px;
      right: -5px;
      text-align: center;
      width: 22px;
      height: 22px;
      line-height: 22px;
      font-size: 14px;
      border-radius: 50px;
      background-color: var(--lightblueColor);
      color: var(--whiteColor);
    }
  }
  h3 {
    margin-bottom: 7px;
    a {
      color: var(--titleColor);
      transition: var(--transition);
    }
  }
  span {
    color: var(--pinkColor);
    margin-bottom: 15px;
    display: block;
  }
  p {
    margin-bottom: 10px;
  }
  .sp-title {
    color: var(--transition);
    margin-bottom: 15px;
    i {
      color: var(--lightblueColor);
      position: relative;
      margin-left: 5px;
      top: 3px;
    }
  }
  .author-content {
    padding: 12px 15px 9px;
    background-color: #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    .content-left {
      span {
        font-size: 14px;
        color: var(--bodyColor);
        display: block;
        margin-bottom: 5px;
      }
      h4 {
        font-size: 22px;
        margin-bottom: 0;
        font-weight: 600;
      }
    }
    .content-right {
      display: inline-block;
      padding: 10px 14px 7px;
      color: var(--whiteColor);
      background-color: var(--pinkColor);
      border-radius: 5px;
      position: relative;
      cursor: pointer;
      transition: var(--transition);
      &:hover {
        background-color: var(--titleColor);
      }
      .author-social {
        padding-left: 0;
        list-style-type: none;
        position: absolute;
        z-index: 1;
        right: 0;
        bottom: 50px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 0;
        li {
          display: block;
          transform: translateY(30px);
          transition: all 0.3s ease-in-out 0s;
          opacity: 0;
          visibility: hidden;
          margin-bottom: 7px;
          &:last-child {
            margin-bottom: 0;
          }
          a {
            display: block;
            i {
              width: 32px;
              height: 32px;
              line-height: 32px;
              text-align: center;
              font-size: 16px;
              color: var(--whiteColor);
              background-color: var(--lightblueColor);
              transition: var(--transition);
              display: inline-block;
              border-radius: 50px;
            }
            &:hover {
              i {
                background-color: var(--pinkColor);
                color: var(--whiteColor);
              }
            }
          }
          &:nth-child(1) {
            transition-delay: 0.1s;
          }
          &:nth-child(2) {
            transition-delay: 0.2s;
          }
          &:nth-child(3) {
            transition-delay: 0.3s;
          }
          &:nth-child(4) {
            transition-delay: 0.4s;
          }
        }
      }
      &:hover {
        .author-social {
          li {
            transform: translateY(0);
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
  &:hover {
    h3 {
      a {
        color: var(--pinkColor);
      }
    }
  }
}
/*=================================
Author Area End
====================================*/

/*=================================
Blog Area
====================================*/
.blog-area {
  background-color: #f4f7f6;
}
.blog-card {
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  border-radius: 15px;
  .blog-img {
    position: relative;
    z-index: 1;
    a {
      display: block;
      img {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      }
    }
    .blog-user {
      position: absolute;
      z-index: 1;
      top: 25px;
      left: 20px;
      background-color: var(--whiteColor);
      padding: 7px 15px 7px 10px;
      display: inline-block;
      border-radius: 50px;
      .blog-user-option {
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
          border-radius: 50%;
          margin-right: 10px;
          width: 30px !important;
          height: 30px !important;
        }
        span {
          font-size: 14px;
          color: var(--titleColor);
          font-weight: 600;
          transition: var(--transition);
          line-height: 1;
          padding-top: 5px;
        }
      }
      &:hover {
        .blog-user-option {
          span {
            color: var(--pinkColor);
          }
        }
      }
    }
    .blog-tag-btn {
      position: absolute;
      display: inline-block;
      right: 30px;
      bottom: -15px;
      padding: 7px 20px 4px;
      border-radius: 50px;
      font-size: 14px;
      color: var(--whiteColor);
      background-color: var(--pinkColor);
      transition: var(--transition);
      &:hover {
        background-color: var(--lightblueColor);
      }
    }
  }
  .content {
    padding: 30px 20px 25px;
    h3 {
      margin-bottom: 10px;
      a {
        color: var(--titleColor);
        transition: var(--transition);
      }
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        color: var(--bodyColor);
        margin-right: 10px;
        font-size: 15px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &:hover {
    .content {
      h3 {
        a {
          color: var(--pinkColor);
        }
      }
    }
  }
}

.blog-details-content {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  max-width: 870px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 15px;
  }
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 29px;
  }
  h3 {
    font-size: 26px;
  }
  h4 {
    font-size: 23px;
  }
  h5 {
    font-size: 20px;
  }
  h6 {
    font-size: 17px;
  }
  a {
    color: var(--mainColor);
    &:hover {
      color: var(--titleColor);
      border-bottom: 1px solid var(--mainColor);
    }
  }
  ul,
  ol {
    margin-bottom: 30px;
    li {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  p {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .content {
    margin-bottom: 30px;
    h1 {
      max-width: 660px;
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        margin-right: 20px;
        color: var(--bodyColor);
        vertical-align: middle;
        margin-bottom: 0;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .blog-preview-img {
    margin-bottom: 30px;
    position: relative;
    img {
      border-radius: 20px;
    }
    .tag-btn {
      position: absolute;
      top: -20px;
      right: 30px;
      display: inline-block;
      background-color: var(--pinkColor);
      color: var(--whiteColor);
      padding: 10px 20px 7px;
      border-radius: 50px;
      font-size: 14px;
    }
  }
  .blog-articel {
    margin-bottom: 30px;
  }
  .blockquote {
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
    background-color: #f3f3f5;
    padding: 30px 30px 30px 100px;
    border-radius: 5px;
    p {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 0;
      font-size: 16px;
      color: var(--titleColor);
    }
    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 30px;
    }
  }
  .blog-portfolio {
    margin-bottom: 30px;
    .blog-portfolio-img {
      margin-bottom: 30px;
      img {
        height: 240px;
        width: 100%;
        border-radius: 15px;
      }
    }
  }
  .article-share-area {
    padding: 25px 30px;
    background-color: var(--whiteColor);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
    margin-bottom: 30px;
    border-radius: 5px;
    .tag-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
      li {
        display: inline-block;
        font-size: 16px;
        color: var(--titleColor);
        margin-bottom: 0;
        &.title {
          position: relative;
          top: 3px;
        }
        a {
          padding: 10px 20px 7px;
          text-align: center;
          background-color: #f6f6f6;
          border-radius: 10px;
          color: var(--lightblueColor);
          font-size: 14px;
          transition: var(--transition);
          &:hover {
            background-color: var(--lightblueColor);
            color: var(--whiteColor);
          }
        }
      }
    }
    .article-social-icon {
      float: right;
      .social-icon {
        padding: 0;
        list-style-type: none;
        margin: 0;
        li {
          display: inline-block;
          margin-right: 10px;
          margin-bottom: 0;
          vertical-align: middle;
          &.title {
            font-size: 16px;
            color: var(--titleColor);
            vertical-align: middle;
            font-family: var(--headerFonts);
            position: relative;
            top: 3px;
          }
          &:last-child {
            margin-right: 0;
          }
          a {
            display: inline-block;
            border: none;
            i {
              width: 32px;
              height: 32px;
              line-height: 32px;
              text-align: center;
              font-size: 16px;
              color: var(--pinkColor);
              background-color: #f14d5d15;
              transition: var(--transition);
              display: inline-block;
              border-radius: 50px;
            }
            &:hover {
              i {
                background-color: var(--pinkColor);
                color: var(--whiteColor);
              }
            }
          }
        }
      }
    }
  }
}

.comments-form {
  h3 {
    margin-bottom: 20px;
  }
  .contact-form {
    h4 {
      font-size: 21px;
    }
  }
}
/*=================================
Blog Area End
====================================*/

/*=================================
Collections Area
====================================*/
.collections-card {
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  border-radius: 5px;
  transition: var(--transition);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.07);
  transition: var(--transition);
  a {
    display: block;
    img {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
  .content {
    padding: 0 20px 20px;
    text-align: center;
    .collections-user {
      position: relative;
      margin-top: -30px;
      margin-bottom: 20px;
      img {
        border-radius: 50%;
        border: 3px solid var(--lightblueColor);
      }
      i {
        position: absolute;
        bottom: 7px;
        right: -5px;
        text-align: center;
        width: 22px;
        height: 22px;
        line-height: 22px;
        font-size: 14px;
        border-radius: 50px;
        background-color: var(--lightblueColor);
        color: var(--whiteColor);
      }
    }
    h3 {
      margin-bottom: 7px;
      a {
        color: var(--titleColor);
        transition: var(--transition);
      }
    }
    span {
      color: var(--bodyColor);
      margin-bottom: 0;
      display: block;
    }
  }
  &:hover {
    transform: translateX(-5px);
    .content {
      h3 {
        a {
          color: var(--pinkColor);
        }
      }
    }
  }
}
/*=================================
Collections Area End
====================================*/

.side-bar-widget {
  margin-bottom: 35px;
  .title {
    font-size: 22px;
    color: var(--titleColor);
    margin-bottom: 20px;
    width: 100%;
    a {
      float: right;
      color: var(--lightblueColor);
      font-size: 15px;
      transition: var(--transition);
      &:hover {
        color: var(--pinkColor);
      }
    }
  }
  .search-form {
    position: relative;
    margin: 0 auto;
    border-radius: 0;
    border: none;
    width: 100%;
    border-radius: 15px;
    .form-control {
      background-color: #f6f6f6;
      border-radius: 15px;
      border: none;
      height: 50px;
      line-height: 50px;
      margin: 0;
      border: 1px solid #f6f6f6;
      padding: 0 25px;
      color: var(--bodyColor);
      &:focus {
        outline: none;
        box-shadow: none;
        border-color: var(--pinkColor);
      }
    }
    button {
      position: absolute;
      top: 0;
      right: 0;
      border: 0;
      border-radius: 0;
      background-color: transparent;
      color: var(--pinkColor);
      height: 50px;
      line-height: 50px;
      font-size: 20px;
      padding: 0 15px;
      transition: var(--transition);
      border-radius: 0 15px 15px 0;
      &:hover {
        background: var(--pinkColor);
        color: var(--whiteColor);
      }
    }
  }

  .form-group {
    margin-bottom: 0;
    position: relative;
    .form-control {
      height: 50px;
      padding: 0 15px;
      border: none;
      color: var(--bodyColor);
      background-color: #f8f8f8;
      font-weight: 400;
      font-size: 15px;
      border-radius: 15px;
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }
  .side-bar-widget-tag {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      padding: 10px 25px 7px;
      margin: 5px;
      transition: 0.7s;
      color: var(--lightblueColor);
      font-size: 14px;
      font-weight: 600;
      background-color: #f6f6f6;
      border-radius: 5px;
      a {
        color: var(--lightblueColor);
      }
      &:hover {
        background-color: var(--lightblueColor);
        a {
          color: var(--whiteColor);
        }
      }
    }
  }
}

.side-bar-widget-categories {
  background-color: var(--whiteColor);
  padding: 30px 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  margin-bottom: 35px;
  .title {
    font-size: 22px;
    color: var(--titleColor);
    margin-bottom: 20px;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      position: relative;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        display: inline-block;
        color: var(--bodyColor);
        width: 100%;
        border-radius: 3px;
        position: relative;
        z-index: 1;
        transition: var(--transition);
        font-weight: 600;
        padding-left: 23px;
        &::before {
          content: "";
          position: absolute;
          top: 40%;
          transform: translateY(-50%);
          left: 0;
          width: 12px;
          height: 12px;
          background-color: transparent;
          border: 1px solid var(--lightblueColor);
          border-radius: 3px;
          transition: var(--transition);
        }
      }
      &:hover {
        a {
          color: var(--pinkColor);
          &::before {
            background-color: var(--lightblueColor);
          }
        }
      }
    }
  }
}

.side-bar-profile {
  padding: 30px 20px;
  background-color: var(--whiteColor);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  border-radius: 15px;
  text-align: center;
  margin-bottom: 35px;
  img {
    border-radius: 15px;
    border: 3px solid var(--pinkColor);
    margin-bottom: 20px;
  }
  h3 {
    margin-bottom: 15px;
  }
  p {
    max-width: 270px;
    margin-left: auto;
    margin-right: auto;
  }
  .social-link {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
      a {
        display: inline-block;
        i {
          width: 32px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          font-size: 16px;
          color: var(--pinkColor);
          background-color: #f14d5d15;
          transition: var(--transition);
          display: inline-block;
          border-radius: 50px;
        }
        &:hover {
          i {
            background-color: var(--pinkColor);
            color: var(--whiteColor);
          }
        }
      }
    }
  }
}

.widget-popular-post {
  padding: 30px 20px;
  background-color: var(--whiteColor);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  border-radius: 15px;
  margin-bottom: 35px;
  .title {
    font-size: 22px;
    color: var(--titleColor);
    margin-bottom: 20px;
  }
  .item {
    overflow: hidden;
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
    .thumb {
      float: left;
      overflow: hidden;
      position: relative;
      margin-right: 15px;
      .full-image {
        width: 85px;
        height: 85px;
        display: inline-block;
        background-size: cover !important;
        background-repeat: no-repeat;
        background-position: center center !important;
        position: relative;
        background-color: var(--whiteColor);
        border-radius: 5px;
        &.bg1 {
          background-image: url(./images/blog/blog-img1.jpg);
        }
        &.bg2 {
          background-image: url(./images/blog/blog-img2.jpg);
        }
        &.bg3 {
          background-image: url(./images/blog/blog-img3.jpg);
        }
      }
    }
    .info {
      overflow: hidden;
      padding: 6px 0;
      .title-text {
        margin-bottom: 10px;
        line-height: 1.2;
        font-size: 20px;
        font-weight: 500;
        max-width: 245px;
        a {
          display: inline-block;
          color: var(--titleColor);
          &:hover {
            color: var(--pinkColor);
          }
        }
      }
      p {
        font-size: 14px;
        color: var(--bodyColor);
      }
    }
  }
}
/*================================
Contact Area
===================================*/
.contact-info-area {
  .section-title {
    p {
      max-width: 420px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
.contact-card {
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  padding: 30px 20px;
  border-radius: 15px;
  i {
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    background-color: var(--pinkColor);
    color: var(--whiteColor);
    border-radius: 50px;
    font-size: 20px;
    display: inline-block;
    margin-bottom: 20px;
    transition: var(--transition);
  }
  h3 {
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 5px;
    a {
      color: var(--bodyColor);
      transition: var(--transition);
    }
  }
  &:hover {
    i {
      background-color: var(--lightblueColor);
    }
    p {
      a {
        color: var(--pinkColor);
      }
    }
  }
}

.contact-form {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  padding: 40px 30px;
  border-radius: 15px;
  max-width: 870px;
  margin-left: auto;
  margin-right: auto;
  h3 {
    font-size: 26px;
    margin-bottom: 20px;
  }
  .form-group {
    margin-bottom: 20px;
    position: relative;
    label {
      margin-bottom: 15px;
    }
    &.checkbox-option {
      position: relative;
      #chb2 {
        position: absolute;
        top: 6px;
        left: 0;
      }
      p {
        padding-left: 25px;
        font-size: 15px;
        color: var(--titleColor);
        a {
          color: var(--pinkColor);
          &:hover {
            color: var(--titleColor);
          }
        }
      }
    }
    .form-control {
      height: 50px;
      color: var(--bodyColor);
      border: none;
      background-color: #f8f8f8;
      font-size: 15px;
      padding: 15px 20px;
      width: 100%;
      border-radius: 30px;
      &:focus {
        outline: none;
        box-shadow: none;
      }
      &::-webkit-input-placeholder,
      &:-ms-input-placeholder,
      &::-ms-input-placeholder,
      &::placeholder {
        color: var(--bodyColor);
      }
    }
    textarea.form-control {
      height: auto;
    }
  }
  .agree-label {
    margin-bottom: 20px;
    position: relative;
    #chb1 {
      position: absolute;
      top: 4px;
      left: 0;
    }
    label {
      font-weight: 400;
      color: var(--titleColor);
      margin-left: 25px;
      a {
        color: var(--pinkColor);
        &:hover {
          color: var(--titleColor);
        }
        &.forget {
          position: absolute;
          right: 0;
        }
      }
    }
  }
  .with-errors {
    float: left;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 0;
    color: #f00;
    font-weight: 400;
    display: block;
  }
  .text-danger {
    font-size: 18px;
    margin-top: 15px;
  }
  .default-btn {
    border: 0;
    outline: none;
    i {
      position: relative;
      top: 2px;
      margin-right: 5px;
    }
  }
}

.user-all-form {
  .contact-form {
    max-width: 750px;
    background-color: var(--whiteColor);
    border: none;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
    .default-btn {
      width: 100%;
      text-align: center;
      &::before {
        width: 850px;
      }
    }
    .sub-title {
      text-align: center;
      display: block;
      color: #888888;
      font-weight: 500;
      margin-top: 30px;
      margin-bottom: 30px;
      z-index: 1;
      position: relative;
      span {
        background-color: var(--whiteColor);
      }
      &::before {
        content: "";
        position: absolute;
        right: 0;
        top: 11px;
        height: 1px;
        width: 100%;
        z-index: -1;
        background: #f0f0f0;
      }
    }
    .login-with-account {
      ul {
        list-style-type: none;
        margin-bottom: 0;
        padding: 0;
        li {
          display: block;
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 0;
          }
          a {
            padding: 10px 30px;
            width: 100%;
            border: 1px solid #c4c4c4;
            color: var(--bodyFonts);
            text-align: center;
            border-radius: 5px;
            font-size: 17px;
            transform: var(--transition);
            i {
              position: relative;
              top: 3px;
              margin-right: 5px;
              transform: var(--transition);
              &.ri-facebook-line {
                color: #2042e3;
                transform: var(--transition);
              }
              &.ri-google-line {
                color: #ff5b4a;
                transform: var(--transition);
              }
            }
            &:hover {
              background-color: var(--pinkColor);
              color: var(--whiteColor);
              i {
                color: var(--whiteColor);
              }
            }
          }
        }
      }
    }
  }
}
/*================================
Contact Area
===================================*/

.terms-conditions-img {
  img {
    margin-bottom: 30px;
  }
}

.single-content {
  margin-bottom: 30px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 15px;
  }
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 29px;
  }
  h3 {
    font-size: 26px;
  }
  h4 {
    font-size: 23px;
  }
  h5 {
    font-size: 20px;
  }
  h6 {
    font-size: 17px;
  }
  a {
    color: var(--pinkColor);
    &:hover {
      color: var(--titleColor);
    }
  }
  ul,
  ol {
    margin-bottom: 30px;
    li {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  p {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

/*=================================
404 Error Area
===================================*/
.error-area {
  .error-content {
    text-align: center;
    position: relative;
    padding: 70px 0;

    h1 {
      font-size: 300px;
      line-height: 0.7;
      font-weight: 700;
      color: var(--titleColor);
      span {
        color: var(--pinkColor);
      }
    }
    h3 {
      margin: 50px 0 0;
      position: relative;
      color: var(--titleColor);
      font-size: 35px;
    }
    p {
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 18px;
      max-width: 520px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
/*=================================
404 Error Area End
===================================*/

/*=================================
Coming Soon Area
===================================*/
.coming-soon-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
  .coming-soon-content {
    text-align: center;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 2;
    padding: 50px 30px;
    background-color: transparent;
    border: 3px solid #d8fade;
    h1 {
      margin-bottom: 0;
      color: var(--titleColor);
      font-size: 50px;
    }
    p {
      font-size: 16px;
      max-width: 600px;
      margin-top: 15px;
      margin-bottom: 0;
      margin-left: auto;
      margin-right: auto;
      color: var(--bodyColor);
    }
    #timer {
      margin-top: 40px;
      div {
        display: inline-block;
        color: var(--pinkColor);
        position: relative;
        width: 80px;
        height: 80px;
        margin: 0 10px;
        font-size: 35px;
        font-weight: 700;
        background-color: transparent;
        border-radius: 5px;
        border: 2px solid #d8fade;
        span {
          display: block;
          text-transform: capitalize;
          margin-top: -15px;
          font-size: 16px;
          font-weight: normal;
          color: var(--titleColor);
        }
        &:last-child {
          margin-right: 0;
          &::before {
            display: none;
          }
        }
        &:first-child {
          margin-left: 0;
        }
        &::before {
          content: "";
          position: absolute;
          right: -50px;
          top: -10px;
          font-size: 70px;
          color: #ffffff;
        }
      }
    }
    .newsletter-form {
      position: relative;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 50px;
      .input-newsletter {
        display: block;
        width: 100%;
        height: 60px;
        border: none;
        background-color: var(--whiteColor);
        padding-left: 15px;
        color: var(--bodyColor);
        outline: 0;
        transition: 0.5s;
        border-radius: 0;
        border: 1px solid #cccccc;
        color: #5d5d5d;
        &:focus {
          border-color: var(--pinkColor);
        }
      }
      .default-btn {
        position: absolute;
        border: 0;
        outline: 0;
        right: 0;
        top: 0;
        height: 60px;
        padding: 0 30px;
        text-transform: uppercase;
        outline: 0;
        color: #ffffff;
        transition: 0.5s;
        font-weight: 500;
        font-size: 15px;
        cursor: pointer;
        background-color: var(--pinkColor);
        border-radius: 0;
        &::before {
          border-radius: 0;
          background-color: var(--titleColor);
        }
        &:hover {
          color: #ffffff;
          background-color: #190f3c;
        }
      }
    }
  }
  #validator-newsletter {
    text-align: left;
    color: #dc3545 !important;
  }
}
/*=================================
Coming Soon Area End
===================================*/

/*=================================
Footer Area
====================================*/
.footer-area {
  background-color: var(--footer-bg);
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.footer-widget {
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  h3 {
    margin-top: 0;
    font-size: 24px;
    margin-bottom: 25px;
    color: var(--titleColor);
    line-height: 1.2;
    position: relative;
    z-index: 1;
    padding-bottom: 10px;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 35px;
      height: 3px;
      background-color: var(--lightblueColor);
    }
    &.title {
      margin-bottom: 20px;
      font-size: 20px;
    }
  }
  p {
    color: var(--bodyColor);
    margin-bottom: 20px;
  }
  .footer-logo {
    margin-bottom: 20px;
  }
  .footer-list {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      display: block;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        color: var(--primary-font-color);
        &:hover {
          color: var(--pinkColor);
          letter-spacing: 0.25px;
        }
      }
      &:hover {
        a {
          color: var(--pinkColor);
          letter-spacing: 0.25px;
        }
      }
    }
  }
}
.newsletter-area {
  .newsletter-form {
    position: relative;
    z-index: 1;
    max-width: 380px;
    .form-control {
      background: var(--whiteColor);
      border-radius: 5px;
      height: 50px;
      line-height: 50px;
      margin: 0;
      border-radius: 5px;
      border: none;
      padding: 0 15px;
      max-width: 100%;
      color: var(--bodyColor);
      border: none;
      transition: var(--transition);
      &:focus {
        outline: none;
        box-shadow: none;
        border: 1px solid var(--pinkColor);
      }
    }
    .subscribe-btn {
      position: absolute;
      top: 0;
      right: 0;
      outline: none;
      box-shadow: none;
      border: none;
      background-color: transparent;
      height: 50px;
      border-radius: 5px;
      padding: 12px 20px 9px;
      font-size: 16px;
      color: var(--whiteColor);
      background-color: var(--pinkColor);
      &:hover {
        background-color: var(--lightblueColor);
      }
    }
    .validation-danger {
      font-size: 18px;
      margin-top: 5px;
      color: red;
    }
  }
}

.copyright-area {
  background-color: var(--footer-bg);
  padding: 15px 0;
}
.copy-right-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  // top: 5px;
  p {
    color: var(--primary-text-color);
    margin-bottom: 0;
    a {
      color: var(--pinkColor);
      font-weight: 500;
      &:hover {
        color: var(--lightblueColor);
      }
    }
  }
  .copy-right-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
      a {
        color: var(--bodyColor);
        &:hover {
          color: var(--pinkColor);
          letter-spacing: 0.25px;
        }
      }
      &:hover {
        a {
          color: var(--pinkColor);
          letter-spacing: 0.25px;
        }
      }
    }
  }
}

.copy-right-social {
  float: right;
  .social-link {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
      a {
        display: inline-block;
        i {
          width: 35px;
          height: 35px;
          line-height: 35px;
          text-align: center;
          font-size: 18px;
          color: var(--pinkColor);
          background-color: #f14d5d15;
          transition: var(--transition);
          display: inline-block;
          border-radius: 50px;
        }
        &:hover {
          i {
            background-color: var(--pinkColor);
            color: var(--whiteColor);
          }
        }
      }
    }
  }
}

.footer-shape {
  .footer-shape1 {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
  }
  .footer-shape2 {
    position: absolute;
    top: 50px;
    right: 20%;
  }
  .footer-shape3 {
    position: absolute;
    bottom: 50px;
    left: 20%;
  }
}

/*=================================
Go Top Area 
====================================*/
.go-top {
  position: fixed;
  top: 60%;
  right: 3%;
  opacity: 0;
  cursor: pointer;
  text-decoration: none;
  z-index: 100;
  transition: 0.5s;
  i {
    transition: 0.5s;
    vertical-align: middle;
    display: inline-block;
    color: var(--whiteColor);
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    background: var(--pinkColor);
    border-radius: 0;
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
  &:hover {
    i {
      transform: translateY(-5px);
      background-color: var(--lightblueColor);
    }
  }
  &.active {
    top: 95%;
    transform: translateY(-95%);
    opacity: 1;
    visibility: visible;
  }
}
/*=================================
Go Top Area End
====================================*/

/*================================
Pagination Area
===================================*/
.pagination-area {
  margin-top: 10px;
  margin-bottom: 30px;
  .page-numbers {
    width: 40px;
    height: 40px;
    line-height: 43px;
    color: var(--titleColor);
    background-color: var(--whiteColor);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.07);
    text-align: center;
    display: inline-block;
    position: relative;
    margin-left: 3px;
    margin-right: 3px;
    font-size: 16px;
    border-radius: 5px;
    &:hover {
      background-color: var(--pinkColor);
      color: var(--whiteColor);
    }
    i {
      position: relative;
      font-size: 23px;
      line-height: normal;
      top: 7px;
    }
    &.current {
      background-color: var(--pinkColor);
      color: var(--whiteColor);
    }
  }
}
/*================================
Pagination Area End
===================================*/

/*================================
Preloader Area
===================================*/
.preloader {
  position: fixed;
  background: var(--whiteColor);
  z-index: 99999;
  height: 100%;
  width: 100%;
  overflow: hidden;
  .spinner {
    margin: auto;
    width: 40px;
    height: 40px;
    position: relative;
    text-align: center;
    position: absolute;
    left: 50%;
    right: 50%;
    margin-left: -20px;
    top: 50%;
    animation: sk-rotate 2s infinite linear;
  }
  .dot1,
  .dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: var(--pinkColor);
    border-radius: 100%;
    animation: sk-bounce 3s infinite ease-in-out;
  }
  .dot1 {
    background-color: var(--lightblueColor);
  }

  .dot2 {
    top: auto;
    background-color: var(--pinkColor);
    bottom: 0;
    -webkit-animation-delay: -2s;
    animation-delay: -1s;
  }
}
/*================================
Preloader Area End
===================================*/

/*==================================
Animation CSS
====================================*/
@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(180deg);
  }
}

@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-bounce {
  0%,
  50% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  75% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes movebounce-up {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes movebounce-down {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

.drawer-before-login-info-wrapper {
  border-bottom: 1px solid var(--primary-border-color);
  padding: 1em;
  font-size: 1.25em;

  .info {
    display: flex;
    gap: 1em;
    flex-shrink: 1;
    font-weight: 700;

    svg {
      width: 1.25em;
      height: 1.25em;
    }
  }
}

.wallet-options-wrapper {
  padding: 1em;

  .wallet-option-tooltip {
    color: var(--primary-text-muted);

    strong {
      color: rgb(32, 129, 226);
      margin: 0 0.5em;
      position: relative;
      padding: 1em 0;

      &:hover {
        cursor: pointer;
      }
      .custom-tooltip {
        padding: 1.5em;
        background-color: var(--color-shade-4);
        color: var(--primary-text-color);
        display: none;
        position: absolute;
        top: calc(100%);
        right: 0;
        font-size: 1em;
        min-width: 400px;
        border-radius: 0.5em;
      }

      .custom-tooltip::before {
        position: absolute;
        top: -0.25em;
        right: 1em;
        content: "";
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid var(--color-shade-4);
      }

      &:hover .custom-tooltip {
        display: block;
      }
    }
  }

  .wallet-options {
    border: 1px solid var(--primary-boder-light);
    border-radius: 0.5em;

    .wallet-options-wrapper {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 1rem;
      border-bottom: 1px solid var(--primary-boder-light);

      &:first-child {
        border-radius: 0.5rem 0.5rem 0 0;
      }

      &:last-child {
        border: none;
        border-radius: 0 0 0.5rem 0.5rem;
      }

      &:hover {
        cursor: pointer;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 -1px 6px rgba(0, 0, 0, 0.1);
      }

      &.disabled {
        cursor: not-allowed;
      }
    }

    .wallet {
      display: flex;
      gap: 1em;
      padding: 0;
      align-items: center;
      transition: all 0.2s ease 0s;
      flex: 1 1;

      &.disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.5;
      }

      button {
        background: none;
        border: none;
        outline: none;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 1rem;
      }

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        border-radius: 0.5em;
      }

      &:first-child:hover {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child:hover {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      img {
        max-width: 35px;
      }

      .wallet-name {
        margin: 0;
        color: var(--primary-text-color);
      }

      .wallet-info {
        margin-left: auto;
        display: flex;
        gap: 1rem;
        align-items: center;

        .spinner-border{
          border-color: var(--icon-color);
          border-right-color: transparent;
        }

      }

      p {
        margin: 0;
        margin-left: auto;
        font-size: 0.85rem;
      }
    }

    &.connecting-wallet {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}

.desktop-nav .navbar .navbar-nav {
  svg {
    width: 1.25em;
    height: 1.25em;
  }

  & > .nav-item {
    display: flex;
    align-items: center;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .nav-item a {
    font-size: 1rem;
    font-weight: 700;
  }
}

.desktop-nav {
  .navbar-brand {
    flex: 1 1 auto;
    margin-left: 0.75rem;

    img {
      object-fit: contain;
    }
  }

  .navbar-items {
    display: flex;
    align-items: center;
    gap: 1em;
    flex: 1 1 auto;
    justify-content: flex-end;
  }

  .header-search-wrapper {
    flex: 1 1 auto;
    margin-right: 1em;

    .nav-widget-form .search-form {
      width: 100%;
    }
  }

  .navbar-actions {
    ul {
      display: flex;
      flex-direction: row;
    }
  }

  @media screen and(max-width : 1999.9px) {
    & .navbar {
      justify-content: space-between !important;

      .hide-in-mobile {
        display: none;
      }

      .mobile-ham {
        display: block;
      }
    }
  }

  @media screen and(min-width : 1200px) {
    & .navbar {
      justify-content: space-between !important;

      .hide-in-mobile {
        display: flex;
      }
      .mobile-ham {
        display: none;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .navbar-brand {
      flex: 1 1 130px;
      padding: 0.5em 0;
    }

    .header-search-wrapper {
      display: none;
    }
    .navbar-nav {
      .nav-item.account-link {
        display: none;
      }
    }
  }
}

.pages-padding {
  padding-top: 70px !important;
}

.profile {
  .profile-banner-section {
    .user-details {
      display: grid;
      place-items: center;
      margin-bottom: 1.5em;

      .profile-cover-image {
        height: 350px;
      }

      .profile-image {
        // position: relative;
        // height: 100px;
        // width: 100px;

        img {
          position: relative;
          // content: "";
          // top: 0%;
          // left: 50%;
          min-height: 200px;
          min-width: 200px;
          max-height: 200px;
          max-width: 200px;
          display: flex;
          // transform: translate(-50%, -50%);
          border-radius: 50%;
          // max-width: fit-content;
          border: 3px solid var(--whiteColor);
          object-fit: cover;
          background-color: var(--bodyColor);
          margin: auto;
          margin-top: -7em;
        }
      }

      h4 {
        margin: 0.5em 0;
      }

      .wallet-address {
        h4 {
          cursor: pointer;
        }
      }
    }
  }

  .user-collections {
    .collection-items {
      // padding: 1.5em 0;
    }

    .collection-actions {
      display: flex;
      margin-bottom: 1.5em;
      gap: 1em;
      flex-wrap: wrap;

      .nav-widget-form {
        flex-grow: 1;
        flex-shrink: 0;
      }

      @media screen and (max-width: 576px) {
        .nav-widget-form {
          width: 100%;
        }
      }

      .search-form {
        width: 100%;
      }

      .price-select-wrapper {
        flex-grow: 1;
        flex-shrink: 0;
      }

      .grid-view-change {
        .grid-change-collection {
          display: flex;
          align-items: center;
          height: 100%;

          .primaryicon {
            height: 100%;
            font-size: 1.5em;
            display: grid;
            place-items: center;
            border: 2px solid var(--primary-boder-light);
            padding: 0.75rem;

            &.active {
              background-color: var(--color-shade-4);
              cursor: not-allowed;
            }
          }

          .primaryicon:first-child {
            border-radius: 0;
            border-right: none;
            border-top-left-radius: 0.5em;
            border-bottom-left-radius: 0.5em;
          }
          .primaryicon:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 0.5em;
            border-bottom-right-radius: 0.5em;
          }
        }
      }
    }
  }
}

.custom-tooltip {
  h4 {
    background-color: var(--primary-text-color);
    padding: 0.5em 1em;
    font-size: 1em;
  }
}

.after-login-drawer {
  .info {
    margin-bottom: 1em;
    display: flex;
    gap: 0.5em;
    flex-shrink: 1;
    border-bottom: 1px solid var(--primary-border-color);
    padding: 1em;
    font-size: 1.25em;
    justify-content: space-between;
    align-items: center;

    .user-wallet {
      display: flex;
      gap: 6px;
      flex-shrink: 1;
      font-weight: 700;
    }

    svg {
      width: 1.25em;
      height: 1.25em;
    }
  }

  .wallet-address {
    display: flex;
    align-items: center;
    background-color: var(--primary-boder-light);
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    cursor: pointer;
    h4 {
      margin: 0;
      font-size: 1em;
      cursor: pointer;
    }
  }

  .walet-balance {
    margin: 1rem;

    .wallet-balance-wrapper {
      border: 2px solid var(--primary-boder-light);
      padding: 1em;
      border-radius: 0.5em;

      &.withbutton {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: none;
        padding-bottom: 0.5em;
      }

      h6 {
        font-size: 1;
        font-weight: 700;
        margin-bottom: 0.5em;
        text-align: center;
      }

      p {
        text-align: center;
        height: fit-content;
      }
    }

    .add-token-btn {
      width: 100%;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  }

  .walet-balance-details{
    margin: 1rem;
    .walet-balance-details-wrapper{
      border: 2px solid var(--primary-boder-light);
      padding: 1em;
      border-radius: 0.5em;
      display: flex;
      gap: 1rem;
      justify-content: space-between;
      align-items: center;

      .currency-details{
        display: flex;
        gap: 1rem;
        align-items: center;

        .price-image{
          max-width: 30px;
        }

      }

    }
  }
}

.collection-links {
  padding: 0;
  .input-group .input-group-text {
    padding: 0.5em;
    width: 50px;
    border-bottom: 0;
    background: none;
    display: grid;
    place-items: center;
    border-color: var(--primary-boder-light);
    background-color: var(--color-shade-4);
    & i {
      font-size: 1.5rem;
      color: var(--icon-color);
    }
  }

  .input-group input {
    padding: 0.75em 1em;
    border-left: 0;
    padding-left: 0;
    background: var(--color-shade-4);
    border-color: var(--primary-boder-light);

    &:focus {
      border-color: #ced4da;
    }
  }

  .input-group:last-child .input-group-text {
    border-bottom: 1px solid var(--primary-boder-light);
  }
}

.collection-form {
  form {
    .network-selection {
      margin: 1em 0;
    }
  }
}

.create-new-item {
  .create-properties-wrapper {
    .create-properties {
      display: flex;
      justify-content: space-between;
      gap: 1em;
      margin: 1em 0;
      border-bottom: 2px solid var(--primary-boder-light);
      padding: 0.5em 0;
      align-items: center;

      .properties-para {
        display: flex;
        gap: 1em;

        svg {
          fill: var(--icon-color);
        }
      }

      .create-btn {
        border-radius: 50%;
        border: 3px solid var(--primary-boder-light);
        width: 60px;
        height: 60px;
        padding: 0;
      }
    }
    .properties-list {
    }
  }

  & svg {
    width: 2rem;
    height: 2rem;
  }
}

.profileButton.custom-input {
  &.video {
    width: 450px;
    height: 300px;
    padding: 0.5rem;
  }

  .preview-image {
    z-index: 0;
  }

  .profileButton-button {
    z-index: 1;
    width: 100%;
    height: 100% !important;

    &:hover {
      background-color: rgba(0, 0, 0, 0.35) !important;
    }

    &:hover svg {
      fill: #fff;
    }
  }
}

.invalid-feedback {
  display: block;
}

.custom-modal {
  .modal-content {
    background-color: var(--primary-modal-color);
    border-radius: 0.5rem;
    opacity: 1;

    .modal-header {
      border-bottom: 2px solid var(--primary-border-color);

      .modal-header-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-size: 1rem;
        font-weight: 600;

        .modal-heading {
          line-height: 1.8;
        }

        i {
          color: var(--icon-color);
        }

        .custom-close {
          padding: 0.25rem;
          border-radius: 50%;
          background-color: transparent;
          height: 40px;
          width: 40px;
          display: grid;
          place-items: center;

          svg {
            width: 1.5rem;
            height: 1.5rem;
            cursor: pointer;
            fill: var(--icon-color);
          }

          i {
            color: var(--icon-color);
          }

          &:hover {
            background-color: rgba(0, 0, 0, 0.1);
          }
        }
      }
    }

    .modal-body {
      max-width: 100%;
    }
  }
}

.add-properties-wrapper {
  overflow: auto;
  max-height: calc(90vh - 200px);

  .properties-layers {
    padding: 0;
    margin: 0;
  }

  .new-properties-wrapper {
    .properties-layer {
      margin-bottom: 1em;

      .row {
        flex-wrap: nowrap;
        padding-left: 0.5em;
        margin-right: 0.5em;
      }

      .form-group {
        margin: 0;

        .form-control {
          border: none;
        }
      }

      .form-group:last-child {
        padding-right: 0.5em;

        .form-control:focus {
          box-shadow: rgba(4, 17, 29, 0.25) 0px 0px 8px 0px;
          border-radius: 0.5em;
        }
      }
    }
  }
}

.custom-input-group {
  gap: 1px;

  &:focus-within {
    box-shadow: rgba(4, 17, 29, 0.25) 0px 0px 8px 0px;
    border-radius: 0.5em;
  }

  input {
    height: 50px;
    color: var(--primary-text-color);
    border: none;
    background-color: var(--color-shade-4);
    font-size: 15px;
    padding: 15px 20px;
    width: 100%;
    border-radius: 0.5em;
    border: none;

    &.is-invalid {
      border-color: var(--danger-color);
    }

    &:focus {
      outline: none;
      box-shadow: none;
      background-color: var(--color-shade-4);
      color: var(--primary-text-color);
    }
    &::-webkit-input-placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder,
    &::placeholder {
      color: var(--color-shade-4);
    }
  }
  span {
    border: none;
    background-color: #f8f8f8;
    border-right: 2px solid var(--primary-border-color);
  }
  button {
    border: none;
    background-color: var(--color-shade-4);
    border-right: 2px solid var(--primary-boder-light);

    &:hover {
      background-color: var(--color-shade-4);
      color: var(--primary-text-color);
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.no-wrap {
  flex-wrap: nowrap;
}

.profile {
  .profile-banner-section {
    .profile-cover-image {
      min-height: 350px;
      max-height: 350px;
      img {
        height: 350px;
        object-fit: cover;
        width: 100%;
        object-position: top;
      }
    }
  }
}

.filter-drawer-wrapper {
  display: grid;
  grid-template-columns: auto 1fr;

  .desktop-filter-wrapper {
    border-right: 1px solid var(--primary-boder-light);

    .filter-opener {
      padding: 1em;
      width: 70px;
      height: 70px;
      display: grid;
      place-items: center;
      cursor: pointer;
      position: sticky;
      content: "";
      top: 70px;

      svg {
        fill: var(--icon-color);
      }

      i {
        fill: var(--icon-color);
      }

      @media screen and (max-width: 768px) {
        & {
          display: none;
        }
      }
    }
  }

  .filter-drawer {
    width: auto;
    height: calc(100vh - 70px);
    transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
    position: sticky;
    content: "";
    top: 70px;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 7px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: var(--scroll-bar-color);
    }

    .filter-drawer-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: var(--accordion-heading-bg);
      padding: 1em;
      border-bottom: 1px solid var(--primary-boder-light);
      cursor: pointer;
      min-width: 350px;
      max-width: 350px;

      svg {
        fill: var(--icon-color);
      }

      i {
        color: var(--icon-color);
      }

      .filter-properties {
        transition: none;
      }

      .filter-collapse-content {
        padding: 1em;
        min-width: 350px;
        max-width: 350px;
        background-color: var(--according-open-bg);

        .price-range-selector {
          .collection-form {
            background: transparent;
            box-shadow: none;
            padding: 0;
            margin-top: 1.5em;
            display: flex;
            gap: 0.5em;
            align-items: center;
          }

          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0;
          }
        }

        .checkbox-wrapper {
          display: flex;
          flex-direction: column;
          gap: 0.5em;
          max-height: 200px;
          overflow-y: auto;
          padding: 0 1rem;
          color: var(--primary-text-color);

          &::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: transparent;
          }

          &::-webkit-scrollbar {
            width: 7px;
            background-color: transparent;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: var(--scroll-bar-color);
          }

          .checkbox-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: var(--primary-text-color);
          }
        }

        @media screen and (min-width: 768.1px) and (max-width: 991px) {
          & {
            min-width: 300px;
            max-width: 320px;
          }
        }
      }

      &.collapse-filter {
        flex-direction: column;
        padding: 0;

        .heading {
          width: 100%;
          padding: 1em;
          background: var(--accordion-heading-bg);
          border-bottom: 1px solid var(--primary-boder-light) !important;
          color: var(--primary-text-color);
          //  &.active{
          //    border-bottom: 1px solid var(--primary-boder-light);
          //  }

          .right-infos {
            margin-left: auto;

            .properties-count {
              line-height: 0;
              margin-right: 0.5em;
              font-weight: 400;
            }
          }

          h6 {
            text-overflow: ellipsis;
            max-width: 220px;
            overflow: hidden;
          }

          svg {
            fill: var(--icon-color);
          }
        }

        .collapse {
          width: 100%;
          border-bottom: 1px solid var(--primary-boder-light);
          background-color: var(--according-open-bg);
        }

        @media screen and (max-width: 768px) {
          .filter-drawer-heading.collapse-filter .collapse {
            width: 100vw;

            .filter-collapse-content {
              width: 100%;
            }
          }
        }
      }

      .heading {
        display: flex;
        gap: 1em;
        align-items: center;

        svg {
          fill: var(--icon-color);
        }

        i {
          color: var(--icon-color);
        }
      }

      @media screen and (min-width: 768.1px) and (max-width: 991px) {
        & {
          min-width: 300px;
          max-width: 300px;
        }
      }
    }

    @media screen and (max-width: 768px) {
      & {
        display: none;
      }
    }
  }

  @media screen and (max-width: 768px) {
    & {
      grid-template-columns: 1fr;
    }
  }
}

.collection-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1em;

  &.small {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));

    .collection-card {
      .card-img-top {
        object-position: top;
        height: 180px;
      }
    }
  }

  @media screen and (max-width: 576px) {
    & {
        grid-template-columns: repeat(auto-fill, minmax(calc(50vw - 4rem), 1fr));
      }
    }

  .collection-card {
    margin: 0;

    .card-img-top {
      // height: 250px;
      object-fit: contain;
      overflow: hidden;
    }

    .image-wrapper{

      .lazyLoder{
        width: inherit;
        height: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }

      .image{
        object-fit: contain;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        border-radius: 0px;
  }
    }

    .price-image {
      border-radius: 0;
      max-width: 1rem;
      margin-right: 5px;
    }

    .image-price {
      display: flex;
      align-items: center;
      // margin-right: 1rem;
    }
  }

  // @media screen and (max-width: 1200px) {
  //   & {
  //     grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

  //     &.small {
  //       grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  //     }
  //   }
  // }

  @media screen and (min-width: 1337px) {
    & {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

      // .card-img-top {
      //   height: 250px;
      // }

      .image-wrapper{
        width:250px;
        height:250px;

      }

      &.small {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

        // .card-img-top {
        //   height: 200px;
        // }

        .image-wrapper{
          width:200px;
          height:200px;
        }

      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1366px) {
    & {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

      // .card-img-top {
      //   height: 200px;
      // }

      .image-wrapper{
        width:200px;
        height:200px;
      }


      &.small {
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));

        // .card-img-top {
        //   height: 160px;
        // }

        .image-wrapper{
          width:160px;
          height:160px;
        }


      }
    }
  }

  @media screen and (min-width: 576px) and (max-width: 768px) {
    & {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

      .image-wrapper{
        width:200px;
        height:200px;
      }


      .small {
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));

        .image-wrapper{
          width:160px;
          height:160px;
        }


      }
    }
  }

  @media screen and (max-width: 576px) {
    & {
      grid-template-columns: repeat(auto-fill, minmax(calc(100vw - 4rem), 1fr));

      & > div {
        width: 100%;
      }

      & .collection-card {
        width: 100%;
        & {
          // .img.card-img-top {
          //   height: auto;
          // }

          .image-wrapper{
            width:calc(100vw - 4rem);
            height:calc(100vw - 4rem);
          }
  
        }
      }

      .small {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      }
    }
  }
}

// mobile filter drawer

.filter-drawer {
  &.mobile {
    padding: 1em;
    width: 100vw;
    height: fit-content;
    position: fixed;
    content: "";
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    left: 0;
    top: unset;

    &.active {
      height: calc(100vh - 60px);
      padding: 0;
      top: 70px;
      padding-bottom: 74px;
    }

    .filter-collapse-content{
      .checkbox-wrapper{
        .checkbox-container{
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
      

    @media screen and (max-width: 768px) {
      & {
        display: block !important;
        z-index: 99;
        border: none;
      }
    }

    @media screen and (min-width: 768.1px) {
      & {
        display: none;
      }
    }

    .mobile-drawer-toggler {
      width: auto;
      padding: 0.5em 2em;
      font-size: 1.15em;
      border-radius: 1em !important;
      margin: auto;
      display: flex;
      gap: 1em;

      @media screen and (min-width: 768.1px) {
        & {
          display: none;
        }
      }
    }

    .filter-drawer-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--whiteColor);
      padding: 1em;
      border-bottom: 1px solid var(--primary-boder-light);
      cursor: pointer;
      width: 100%;

      .filter-properties {
        transition: none;
      }

      .filter-collapse-content {
        padding: 1em;
        min-width: 400px;
        background-color: var(--according-open-bg);

        .price-range-selector {
          .collection-form {
            background: transparent;
            box-shadow: none;
            padding: 0;
            margin-top: 1.5em;
            display: flex;
            gap: 0.5em;
            align-items: center;
          }

          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0;
          }
        }

        .checkbox-wrapper {
          display: flex;
          flex-direction: column;
          gap: 0.5em;
          max-height: 200px;
          overflow-y: auto;
          padding: 0 1rem;
        }
      }

      &.collapse-filter {
        flex-direction: column;
        // border-bottom: 1px solid var(--primary-boder-light) !important;
        padding: 0;

        .heading {
          width: 100%;
          padding: 1em;
          background: var(--color-shade-4);
          border-bottom: 1px solid var(--primary-boder-light);

          //  &.active{
          //    border-bottom: 1px solid var(--primary-boder-light);
          //  }

          .right-infos {
            margin-left: auto;

            .properties-count {
              line-height: 0;
              margin-right: 0.5em;
              font-weight: 400;
            }
          }

          svg {
            fill: var(--icon-color);
          }

          i {
            fill: var(--icon-color);
          }

          h6 {
            text-overflow: ellipsis;
            max-width: 220px;
            overflow: hidden;
          }
        }

        .collapse {
          width: 100%;
        }
      }

      .heading {
        display: flex;
        gap: 1em;
        align-items: center;
      }
    }

    @media screen and (max-width: 768px) {
      .filter-drawer-heading.collapse-filter .collapse {
        width: 100vw;

        .filter-collapse-content {
          width: 100%;
          min-width: unset;
        }
      }
    }
  }
}

// .collection-card {
//   .card-img-top {
//     height: 250px;
//     object-fit: cover;
//   }
// }

.collection-tabs {
  border-bottom: 1px solid var(--primary-boder-light);
}

.explore-tabs {
  border-bottom: 1px solid var(--primary-boder-light);
}

.explore-contents {
  margin: 1em 0;
}

.all-nfts {
  .collection-actions {
    display: flex;
    margin-bottom: 1.5em;
    gap: 1em;
    flex-wrap: wrap;

    .result-count {
      display: flex;
      align-items: center;
      width: 200px;
    }

    .nav-widget-form {
      flex-grow: 1;
      flex-shrink: 0;
    }

    @media screen and (max-width: 576px) {
      .nav-widget-form {
        width: 100%;
      }
    }

    .search-form {
      width: 100%;
    }

    .price-select-wrapper {
      flex-grow: 1;
      flex-shrink: 0;
    }

    .grid-view-change {
      .grid-change-collection {
        display: flex;
        align-items: center;
        height: 100%;

        .primaryicon {
          height: 100%;
          font-size: 1.5em;
          display: grid;
          place-items: center;
          border: 2px solid var(--primary-boder-light);

          &.active {
            background-color: var(--color-shade-4);
            cursor: not-allowed;
          }
        }

        .primaryicon:first-child {
          border-radius: 0;
          border-right: none;
          border-top-left-radius: 0.5em;
          border-bottom-left-radius: 0.5em;
        }
        .primaryicon:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-top-right-radius: 0.5em;
          border-bottom-right-radius: 0.5em;
        }
      }
    }
  }
}

.profile {
  .top-head-icons {
    & .head-icon {
      justify-content: center;

      .btn-header {
        font-size: 1.25rem;
        display: grid;
        place-items: center;
      }

      * svg {
        fill: var(--icon-color);
      }

      * i {
        color: var(--icon-color);
      }
    }

    @media screen and (max-width: 576px) {
      & {
        position: relative;
        top: unset;
        right: unset;
        margin-bottom: 1.5em;
      }

      & .head-icon {
        justify-content: center;

        .btn-header {
          font-size: 1.25rem;
          display: grid;
          place-items: center;
        }
      }
    }
  }
}

.previewButton.custom-input-banner {
  height: 400px;

  &:hover {
    .previewButton-button {
      display: grid;
    }
  }

  .preview-image {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 2px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 15px;
    }
  }

  .preview-video {
  }

  .previewButton-button {
    z-index: 1;
    width: 100%;
    height: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
    background: none;
    display: none;

    &:hover {
      background-color: rgba(0, 0, 0, 0.35) !important;
    }

    &:hover svg {
      fill: #fff;
    }
  }
}

.footer-widget {
  .footer-list {
    li {
      a:hover {
        letter-spacing: 0 !important;
      }
    }
  }
}

.explore-contents {
  .explore-img {
    img {
      object-fit: cover;
    }
  }

  .card-head-icon {
    button {
      z-index: 99;
    }
  }
}

.collection-card-loader {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--color-shade-4);
  background-clip: border-box;
  border: 1px solid var(--primary-boder-light);
  width: 100%;
  transition: 1s all cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border-radius: 1rem;
  overflow: hidden;

  .collection-img-loader {
    line-height: inherit;
    height: 200px;
  }

  .collection-details-loader {
    padding: 1rem;
    display: grid;
    gap: 1em;
    grid-template-columns: repeat(2, 1fr);

    span:last-child {
      grid-column: span 2;
    }
  }

  .collection-card-footer-loader {
    -webkit-box-align: center;
    border-top: none;
    padding: 12px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: var(--card-footer-gradient);
    display: flex;
    justify-content: space-between;
  }

  @media screen and (min-width: 1337px) {
    & {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

      .collection-img-loader {
        height: 250px;
      }

      &.small {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

        .collection-img-loader {
          height: 200px;
        }
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1366px) {
    & {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

      .collection-img-loader {
        height: 200px;
      }

      &.small {
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));

        .collection-img-loader {
          height: 160px;
        }
      }
    }
  }

  @media screen and (min-width: 576px) and (max-width: 768px) {
    & {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

      .small {
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
      }
    }
  }

  @media screen and (max-width: 576px) {
    & {
      grid-template-columns: repeat(auto-fill, minmax(calc(100vw - 4rem), 1fr));

      & > div {
        width: 100%;
      }

      &.small {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      }
    }
  }
}

.trending-area-loader {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1em;

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.account-image {
  height: 30px;
  width: 30px;
  object-fit: cover;
  line-height: 1;

  img {
    border-radius: 50%;
    height: 30px;
    width: 30px;
    object-fit: cover;
  }
}

.collection-filter-properties {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  margin: 1em 0;
  .properties-panel {
    margin: 0;
    padding: 0.75em 2em;
  }
}

.collection-activity {
  width: calc(100vw - 400px - 2em);

  @media screen and (max-width: 768px) {
    width: 100vw;
  }
}

.activity-table-wrapper {
  overflow-x: auto;
  overflow-y: visible;

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    height: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--scroll-bar-color);
  }

  .table-header {
    display: inline-flex;
    // justify-content: space-around;
    gap: 1rem;
    padding: 1rem;
    margin: 0 1rem;
    border-bottom: 1px solid var(--primary-boder-light);
    background: var(--bodyColor);

    .table-header-item {
      width: 150px;
      display: flex;
      flex: 0 0 150px;
      -webkit-box-pack: start;
      justify-content: center;
      -webkit-box-flex: 0;
      -webkit-box-align: center;
      align-items: center;

      &:first-child {
        justify-content: start;
      }

      .table-heading {
        margin: 0;
      }
    }

    @media screen and (min-width: 1600px) {
      display: flex;
      justify-content: space-around;
    }
  }

  .table-body-wrapper {
    margin: 1em 0;
    display: inline-flex;
    flex-direction: column;

    .table-body {
      display: flex;
      // justify-content: space-around;
      gap: 1em;
      padding: 1.5rem;
      margin: 0 1rem;
      border-radius: 1rem;

      .table-body-item {
        width: 150px;
        display: flex;
        -webkit-box-pack: start;
        justify-content: center;
        flex: 0 0 150px;
        -webkit-box-flex: 0;
        -webkit-box-align: center;
        align-items: center;
        color: var(--primary-text-color);

        &:first-child {
          justify-content: start;
          color: var(--primary-text-color);
          svg {
            fill: var(--icon-color);
          }
          i {
            color: var(--icon-color);
          }
        }

        .item-details {
          display: flex;
          gap: 10px;
          align-items: center;

          img {
            max-width: 60px;
            border-radius: 10px;
          }
        }
      }

      &:hover {
        box-shadow: rgba(4, 17, 29, 0.25) 0px 0px 8px 0px;
      }

      @media screen and (min-width: 1600px) {
        display: flex;
        justify-content: space-around;
      }
    }
    @media screen and (min-width: 1600px) {
      display: grid;
    }
  }
}

.activity-filter-payment {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;

  .payment-types {
    padding: 0.75rem;
    border: 1px solid var(--properties-border-color);
    background-color: rgba(21, 178, 229, 0.06);
    border-radius: 6px;
    min-width: 100px;

    h6 {
      font-weight: 500;
      text-align: center;
    }
  }
  .payment-types.active {
    h6 {
      color: var(--properties-primary);
    }
  }
}

.profile-loader {
  .profile-banner-section-loader {
    .profile-head {
      position: relative;

      .user-details {
        display: grid;
        place-items: center;
        margin-bottom: 1.5em;

        & > span {
          height: 100px;
          width: 100px;
        }

        .profile-image-loader {
          position: absolute;
          content: "";
          top: 0%;
          left: 50%;
          height: 200px;
          width: 200px;
          display: flex;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          border: 3pxsolid var(--whiteColor);
        }
      }

      .links-loader {
        position: absolute;
        top: 10%;
        right: 3%;

        & > span {
          display: flex;
          gap: 1px;
        }

        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }
  }
  .collection-box-loader {
    margin-bottom: 1.5rem;

    & > span {
      display: flex;
      gap: 1px;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  .desc-loader {
    max-width: 800px;
    margin: auto;
    margin-bottom: 3rem;

    & > span {
      max-width: 800px;
    }

    @media screen and (max-width: 768px) {
      margin: 2rem;
    }
  }

  .mobile-links-loader {
    & > span {
      display: flex;
      gap: 1px;
      justify-content: center;
      margin-bottom: 1.5rem;
    }

    @media screen and (min-width: 768.1px) {
      display: none;
    }
  }
}

.top-sellers-area-loader {
  padding-top: 45px;

  .top-sellers-item-loader {
    display: flex;
    flex-direction: row;
    gap: 1em;
    padding: 1em;

    .top-seller-details {
      flex: 1 1 auto;
    }
  }
}

.explore-detail {
  h4 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  p:last-child {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.lazyLoder {
  .lazyImageLoder {
    display: block;
    width: 100%;
    height: 100%;
    display: grid;
  }

  .lazyLoder--isLoading {
    .img {
      display: none;
    }
  }

  &:not(.lazyLoder--isLoading) {
    .img {
      display: block;
      object-fit: cover;
    }
  }

  &:not(.lazyLoder--isLoading) {
    .lazyImageLoder {
      display: none;
    }
  }
}

.explore-card-loader {
  .explore-card-wrapper {
    margin: 0.5em;
    border: 1px solid var(--primary-boder-light);
    border-radius: 10px;
    padding: 0;
    line-height: 1;

    .explore-user-details {
      padding-bottom: 2rem;

      .explore-user-image-loader {
        height: 50px;
        position: relative;

        .explore-user-image {
          position: absolute;
          content: "";
          top: 0;
          left: 50%;
          height: 100px;
          width: 100px;
          /* display: flex; */
          transform: translate(-50%, -50%);
          border-radius: 50%;
          -webkit-box-align: center;
          align-items: center;
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          overflow: hidden;
          border-radius: 50%;
          border: 3px solid var(--primary-boder-light);
          object-fit: cover;
        }
      }

      .explore-user-info-loader {
        display: grid;
        place-items: center;
        gap: 0.5em;
        margin-top: 1rem;

        br {
          display: none;
        }

        & span:first-child {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }

      .explore-card-description {
        padding: 0 1em;
        margin-top: 1em;
      }
    }
  }
}

.explore-banner-image {
  img {
    height: 350px;
    width: 100%;
    object-fit: cover;
  }
}

.collection-card-wrapper {
  position: relative;
}

.buttons-wrapper {
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
  justify-content: center;
}

.edit-collection-loader {
  .category-loader {
    & > span {
      display: flex;
      gap: 0.5em;
      flex-wrap: wrap;
    }
  }

  .buttons-loader {
    & > span {
      display: flex;
      gap: 0.5em;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

.collection-search {
  .input-group {
    padding-right: 50px;

    button {
      background-color: var(--pinkColor);
      color: var(--whiteColor);
      z-index: 2;
      width: 50px;

      svg {
        fill: var(--static-white-color);
      }
    }
  }
}

.profile-setting-wrapper {
  .profile-setting-tabs-switcher {
    border-right: 1px solid var(--primary-boder-light);
    height: calc(100vh - 70px);
    padding: 1em;
    position: sticky;
    top: 70px;

    .profile-heading{
      margin-bottom: 1rem;
    }

    .nav.nav-pills {
      display: flex;
      flex-direction: column;
      gap: 1em;
      flex-wrap: wrap;
    }

    .nav-link {
      padding: 1rem;
      display: flex;
      gap: 0.5em;
      color: var(--primary-text-color);
      font-weight: 600;
      align-items: center;
      border-radius: 0.5rem;
      line-height: 1;

      &.active {
        background: var(--tabs-active-bg);
      }

      svg {
        width: 25px;
        height: 25px;
      }
    }

    @media screen and (max-width: 768px) {
      height: auto;
      border: none;
      padding-bottom: 0;

      .nav.nav-pills {
        flex-direction: row;
      }

      .setting-tab.nav-item {
        display: flex;

        .nav-link {
          display: flex;
          gap: 0.5rem;
        }
      }
    }
  }
}

.tab-content-wrapper {
  padding: 1rem 2rem;

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }

}

.collection-links {
  input {
    border-bottom: none;
  }

  .link-input:first-child .input-group-text {
    border-top-left-radius: 1rem;
  }

  .link-input:first-child input {
    border-top-right-radius: 1rem;
  }

  .link-input:last-child .input-group-text {
    border-bottom-left-radius: 1rem;
  }

  .link-input:last-child input {
    border-bottom-right-radius: 1rem;
    border-bottom: 1px solid var(--primary-boder-light) !important;
  }
}

.custom-collection-links {
  .custom-input-link {
    display: flex;
    border: 1px solid var(--primary-boder-light);

    .custom-input-icon {
      padding: 0.75rem;
      display: grid;
      place-items: center;

      i {
        font-size: 1.5rem;
      }
    }

    input {
      border: none;
      padding-left: 0;
    }

    :first-child {
      background: red;
    }
  }
}

.listing-header-sec {
  .listing-product-item {
    display: flex;
    align-items: center;
    gap: 1em;

    i {
      cursor: pointer;
      font-size: 1.5rem;
      margin: 0;
    }
  }
}

.etherum-img {
  gap: 8px;
  // justify-content: center;

  h3 {
    margin: 0;
  }
}

.left-single-card {
  a {
    width: 100%;
    height: 100%;
    img {
      min-height: 100%;
      max-height: 100%;
      object-fit: cover;
      width:100%;
      max-width: 100%;
    }
  }
}

.fancybox__caption {
  .caption-title {
    color: #fff;
    text-align: center;
  }

  .caption-text {
    color: #fff;
    text-align: center;
  }
}

.single-image-loader {
  .single-details-info-loader.hide-loader-desktop {
    flex-direction: column;
    .item-links-loader {
      > span {
        justify-content: flex-start;
      }
    }
  }

  .single-properties-loader {
    margin-top: 1em;
  }

  .single-details-info-loader {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;

    .item-Details-loader {
      flex: 1 1 auto;
    }

    .item-links-loader {
      > span {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .owner-details-loader {
    display: flex;
    gap: 3em;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
  }

  .transaction-table-loader {
    margin: 2em 0;
  }

  .other-items-loader {
    margin-bottom: 2em;
  }

  @media (max-width: 991px) {
    .hide-loader-mobile {
      display: none;
    }
  }

  @media (min-width: 991px) {
    .hide-loader-desktop {
      display: none;
    }
  }
}

.border-top-left-radius {
  border-top-left-radius: 1rem !important;
}

.border-top-right-radius {
  border-top-right-radius: 1rem !important;
}

.total-price-card {
  .price-icon {
    display: block;
    max-width: 1rem;
    margin-right: 0.5rem;
  }
}

.complete-listing-header-sec {
  gap: 0.5rem;
  flex-wrap: wrap;

  .complete-listing-right-sec {
    padding-right: 1rem;
  }
}

.image-price {
  .price-icon {
    max-width: 1rem;
  }
}

.lisiting-item-loader {
  .listing-item-loader-inputs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .listing-fee-sec {
    padding: 1rem 0;
  }

  .loader-price-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.header-search {
  margin-right: 2px;

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--scroll-bar-color);
  }

  .search-list {
    &:last-child {
      border: none;

      // a{
      //   padding-bottom: 0;
      // }
    }

    &.search-title:first-child {
      padding-top: 0;
    }

    .search-text {
      .collection-item-count {
        margin-left: auto;
      }
    }
  }
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  max-height: calc(100vh - 70px - 2rem) !important;
  overflow: hidden;
  padding: 0 !important;
  transition: all 0.1s ease-in-out;

  &.not-scrollable {
    padding: 0 !important;
  }

  // &:not(.not-scrollable){
  //   & li:last-child{
  //     a{
  //       padding-bottom: 0;
  //     }
  //   }

  //   & li:first-child{
  //     a{
  //       padding-top: 0;
  //     }
  //   }
  // }

  & li a:hover {
    color: var(--titleColor);
  }

  .custom-dropdown-wrapper {
    // margin: 0.5rem 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: var(--scroll-bar-color);
    }

    max-height: calc(100vh - 70px - 2rem) !important;
    overflow: auto;

    .category-icon {
      width: 1.25rem;
      height: 1.25rem;
    }

    li a {
      gap: 0.5rem;
    }
  }

  & svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.listing-item-card-details {
  .image-price {
    align-items: center;
  }
}

.custom-accordion-contents {
  display: flex;
  flex-direction: column;

  button {
    width: fit-content;
  }
}

.selected-filters {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;

  .properties-panel {
    max-width: fit-content;
    margin: 0;
    display: flex;
    align-items: center;
    padding: 0.75rem;
    gap: 0.75rem;

    &.properties{
      p{
        flex-direction: column;
      }
    }

    p {
      line-height: 1;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      width: 100%;
      justify-content: space-between;
      text-transform: capitalize;

      svg {
        cursor: pointer;
      }
    }
  }
}

.link-btn {
  background-color: transparent;
  color: var(--lightblueColor);
  border: none;
  font-weight: 500;
}

.link-btn:hover,
.link-btn:active {
  background-color: transparent;
  color: var(--lightblueColor);
  border: none;
}

.custom-spinner {
  width: 1.5rem;
  height: 1.5rem;
}

.header-search {
  max-height: calc(100vh - 86px - 1rem);

  .search-list {
    &.search-title {
      padding: 0.5rem;
      font-weight: 600;
      color: var(--primary-text-muted);
    }

    p:not(.collection-item-count) {
      font-weight: 600;
      color: var(--primary-text-color);
    }
    p.collection-item-count {
      font-size: 0.8rem;
      color: var(--primary-text-muted);
    }
  }
}
.collection-details-list {
  margin-top: 16px;
  border-bottom: 1px solid var(--primary-boder-light);
  padding-bottom: 16px;

  .collection-details-list-item {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;

    .collection-details-list-content {
      display: flex;

      i {
        color: var(--icon-color);
      }

      .collection-details-list-main {
        margin-left: 16px;

        .collection-details-list-label {
          font-weight: 600;
          font-size: 16px;
          display: block;
          color: var(--primary-text-color);
        }
      }
    }

    .collection-details-list-side {
      -webkit-box-pack: center;
      justify-content: center;
      flex-direction: column;
      display: flex;

      .collection-details-list-toggle {
        appearance: none;
        outline: none;
        border-radius: 100px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        padding: 0px 6px;
        height: 24px;
        width: 48px;
        cursor: pointer;
        transition: all 0.3s ease-out 0s;
        background-color: rgb(138, 147, 155);

        &::after {
          content: "";
          width: 14px;
          height: 14px;
          background-color: rgb(255, 255, 255);
          border-radius: 50%;
          transition: all 0.3s ease-out 0s;
        }
      }
    }
  }
}

.button-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
}

.card {
  background-color: var(--primary-card-color) !important;
}

.btn-header {
  border-color: var(--primary-boder-light);
}

.description-btn button i {
  color: var(--icon-color);
}

.nav-item.theme-switcher {
  padding: 0.375rem 0.75rem !important;
}

.theme-changer-wrapper {
  border-radius: 50%;
  height: 100%;
  display: grid;
  place-items: center;
  width: 30px;
  height: 30px;
  overflow: hidden;
  position: relative;

  .theme-changer {
    cursor: pointer;
    width: 30px;
    height: 30px;

    svg {
      fill: var(--icon-color);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 24px;
      height: 24px;
    }

    &.theme-changed {
      svg {
        animation: fadeup 0.5s ease-in-out alternate forwards;
      }
    }
  }
}

@keyframes fadeup {
  from {
    transform: translate(-50%, -50%);
    left: 50%;
  }
  to {
    left: 0;
    transform: translate(-100%, -50%);
  }
}

.MuiTableRow-root {
  background-color: var(--color-shade-4);
}

.MuiTableCell-body {
  border: none;
  text-align: center !important;
}

.default-btn:hover {
  color: var(--primary-button-color) !important;
}

.static-contents * {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--titleColor) !important;
    background: transparent !important;

    span {
      color: var(--titleColord) !important;
      background: transparent !important;
    }
  }

  p {
    color: var(--primary-text-muted) !important;
    background: transparent !important;

    > span {
      color: var(--primary-text-muted) !important;
      background: transparent !important;
    }
  }

  b {
    span {
      background-color: transparent !important ;
    }
  }

  font {
    color: var(--primary-text-muted) !important;
    background-color: transparent !important;
  }
}

.static-page-loader {
  .sections {
    display: grid;
    gap: 0.5rem;
  }
}

.nav-item.account-link {
  .custom-dropdown-menu {
    top: 100% !important;
  }
}

.import-collection {
  margin: 15vh 0;

  .heading {
    margin-bottom: 1rem;

    h2 {
      margin-bottom: 2rem;
    }
  }

  .input-button {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;

    input {
      flex: 1 1 80%;
      background-color: var(--header-search-bar-color);
      border-radius: 0.5rem;
      border: 1px solid var(--primary-border-color);
      height: 50px;
      line-height: 50px;
      font-size: 15px;
      margin: 0;
      padding: 0 1rem;
      color: var(--primary-text-color);
    }
  }
}

@media screen and (max-width: 576px) {
  .footer-align {
    justify-content: start;
  }
  .footer-area .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .footer-align.logo {
    justify-content: center;
  }
}

.nav-widget-form {
  margin-left: 0;
}

.mobile-search {
  // max-height: 50px;
  // overflow-y: hidden;

  &.nav-widget-form {
    flex: 0 0 auto;

    .search-form .form-control {
      border-radius: 0;
      box-shadow: none;
      border: none;
      border-top: 1px solid var(--primary-boder-light);
      border-bottom: 1px solid var(--primary-boder-light);
    }
  }

  .header-search {
    max-height: 400px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-right: 0.5rem;
    border-bottom: 1px solid var(--primary-boder-light);
  }
}

label {
  color: var(--primary-text-color);
}

.transfer-modal-info-sec.collection-form {
  margin: 0;
  padding: 0;
  box-shadow: none;
}

.invalid-feedback {
  font-weight: 600;
}

.drawer-closer {
  margin: 1rem;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.wallet-drawer-closer {
  margin-left: auto;
  cursor: pointer;
}

.wallet-login-drawer-closer {
  cursor: pointer;
}

.profile-banner-section {
  @media screen and (max-width: 576px) {
    .profile-cover-image img {
      object-fit: cover;
      object-position: center;
    }
  }
  @media screen and (min-width: 577px) and (max-width: 1199px) {
    .profile-cover-image img {
      object-fit: cover;
      object-position: center;
    }
  }
}

.custom-accordion-heading {
  display: flex;
  gap: 0.5rem;
  align-items : center;

  .custom-accordion-spinner {
    position: relative;
    width: 30px;
    height: 30px;

    .heading {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: 600;
    }

    &.loading{
      .check-spinner__worm-a {
        animation: worm-a 1.2s linear infinite;
      }
    }

    &.loading-complete{
      .heading{
        opacity: 0;
      }
    }

    .check-spinner {
      overflow: visible;
      width: 30px;
      height: 30px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .check-spinner__worm-a,
      .check-spinner__worm-b {
        visibility: hidden;
        stroke-width: 1rem;
      }

    .check-spinner__circle{
      fill : var(--according-open-bg)
    }

      &.loading-complete {

        .check-spinner__worm-a {
          opacity:0;
        }

        .check-spinner__worm-b {
          animation: worm-b 1.2s linear forwards;
          stroke-dashoffset: 0.01;
        }
        .check-spinner__circle {
          animation: circle 0.2s cubic-bezier(0, 0, 0.42, 1.33) forwards;
        }
      }
    }


    /* Animations */
    @keyframes worm-a {
      from {
        stroke-dashoffset: 36.1;
        visibility: visible;
      }
      to {
        stroke-dashoffset: -252.9;
        visibility: visible;
      }
    }
    @keyframes worm-b {
      from {
        animation-timing-function: linear;
        stroke-dasharray: 0 0 72.2 341.3;
        visibility: visible;
      }
      69.7% {
        animation-timing-function: ease-out;
        stroke-dasharray: 0 207 45 341.3;
        visibility: visible;
      }
      to {
        animation-timing-function: ease-out;
        stroke-dasharray: 0 297 45 341.3;
        visibility: visible;
      }
    }
    @keyframes circle {
      from {
        r: 0;
      }
      to {
        r: 50px;
      }
    }
    @keyframes reappear {
      from {
        visibility: hidden;
      }
      to {
        visibility: visible;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px){
  .copy-right-text {
    justify-content: center;
  }
}

.single-details-mobile{
  .page-title{
    a {
      margin-bottom: 1rem;
    }
  }
}

.MuiSwitch-switchBase.Mui-checked{
  color: var(--lightblueColor) !important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track{
  background-color: var(--lightblueColor) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media only screen and (min-width: 992px) and (max-width: 1199px){
  .banner-card .content .banner-user-list .banner-user-list-img img {
    width: 35px;
    height: 35px;
    object-fit: contain;
  }
  .banner-card .content {
    padding: 1rem;
  }
}


.more-collection {
  .accordion{
   .collapse{
     background-color: var(--according-open-bg);
   } 
  }
}

.empty-para{
  height:15px;
}

.btn-header{
  background-color: transparent;
}

input::placeholder , textarea::placeholder{
  color: var(--primary-text-muted) !important;
}

.complete-checkout-modal {

  .modal-footer{
    padding: 1rem 0.75rem;
    border-color: var(--primary-border-color);
  }

}

.checkbox-method-area .checkbox-method p [type="radio"]:not(:checked) + label{
  color: var(--primary-text-color);
}

.unreviewed-table-sec {

  .unreviewed-table-card{
    border-bottom: none;
    border-color: var(--primary-boder-light);
  }

  
  .unreviewed-table-card.last{
    border-bottom: 1px solid var(--primary-boder-light) !important;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

}

.collection-btn{
  background-color: var(--pinkColor);
}

.listing-item-card-details{
  .listing-item-card-info{
    flex-shrink: 0;
  }
  .image-price{
    flex-grow: 0;
    max-width: 50%;

    span{
      text-overflow: initial;
      overflow-wrap: break-word;
    }

  }

}

.rangeCalender{

  .rangerCalender-heading{
    display: flex;
    gap: 1rem;
    justify-content: space-between;
  }

  .toggler{
    width: 100%;
    background-color: var(--color-shade-4);
    border: 1px solid;
    border-color: var(--primary-boder-light);
    color: var(--primary-text-color);
    cursor: pointer;
    padding: 1rem;
    display: inline-flex;
    flex-direction: row;
    margin-bottom: 1rem;
    gap: 0.75rem;
    align-items: center;
    border-radius: 0.25rem;

    &:hover{
      transition: all 0.2s ease 0s;
      box-shadow: rgba(4 ,17, 29 , 0.25) 0px 0px 8px 0px;
    }

    .calender-icon{
      svg{
        fill : var(--icon-color)
      }
    }

    .selected-range{
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: start;
      align-self: center;
    }

  }
}

.listing-item-card-sec{
  position: sticky;
  top: calc(70px + 2rem); 
}

.range-calender{

  &.mobile{

    .rdrCalendarWrapper.rdrDateRangeWrapper{
      width : 100%;
      background: transparent;

      .rdrMonthAndYearWrapper{
        color: var(--primary-text-color);
      }
  
      .rdrDateDisplayWrapper{
        background-color: transparent;
      }
  
      .rdrMonths {
        .rdrMonth{
          width: 100%;
        }
      }
  
    }
  }

  .rdrCalendarWrapper{
    width : 100%;
    background: transparent;

    .rdrMonthAndYearWrapper{
      color: var(--primary-text-color);
    }

    .rdrDateDisplayWrapper{
      background-color: transparent;
    }

    .rdrMonths {
      .rdrMonth{
        width: 100%;
      }
    }
  }

  .date-range-header{
   .heading{
    display: grid;
    grid-template-columns: auto 40px auto;
    gap: 1rem;

    .staring-date{
      width: 100%;
    }

    .ending{
      width: 100%;
    }

   }
   .date-input{
    display: grid;
    gap: 1rem;
    place-items: center;
    grid-template-columns: 1fr auto 1fr;
    
    input{
      padding: 0.75rem;
      color: var(--primary-text-muted);

      &:disabled{
        background-color: var(--color-shade-4);
        border-color: var(--primary-boder-light);
      }

    }

   } 
  }
 
  .rdrCalendarWrapper.rdrDateRangeWrapper{
    width : 100%;
    background-color: transparent;

    .rdrMonthAndYearPickers{
      color: var(--primary-text-color);
      font-size: 1.25rem;
    }

    .rdrDateDisplayWrapper{
      background-color: transparent;
    }

    .rdrMonths {
      .rdrMonth{
        width: 50%;

        .rdrDayDisabled{
          background-color: transparent;
          cursor: not-allowed;
         .rdrDayNumber span {
            color: var(--primary-text-muted);
            
          }
        }

        .rdrDayNumber span{
          color: var(--primary-text-color);
        }

      }
    }

  } 
}

.popup-backdrop{
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.35);

  &.not-popup {
    background-color:transparent;
  }

}

.poup-closer  {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;

  svg{
    fill: var(--icon-color);
  }

}

.time-picker{
  display: flex;
  gap: 1.5rem;

  .custom-timeinput{
    width: 100%;
    padding: 0.5rem 1rem;
    border: 1px solid var(--primary-boder-light);
    border-radius: 0.25rem;
    padding-bottom: 0;

    &.invalid-field{
      border-color: var(--danger-color);
    }

    & .MuiInputBase-root:before {
      border: none;
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before{
      border: none;
    }

    .MuiInput-underline:after{
      border: none;
    }
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
  }

}


.noBodyOverflow {
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  overflow: hidden;
}

.single-image-price-wrapper{
  display: flex;
  align-items: center;
  // margin-bottom: 1rem;
  gap: 1rem;
}

.offertable{
  
  &.etherum-img{
    justify-content: center;
  }

}

.item-details-area{
  .etherum-img{
    justify-content: center;
  }
}

.rdrMonthAndYearPickers{
  color: var(--primary-text-color);
  font-size: 1.25rem;
}

.rdrDateDisplayWrapper{
  background-color: transparent;
}

.rdrMonths {
  .rdrMonth{
    width: 50%;

    .rdrDayDisabled{
      background-color: transparent;
      cursor: not-allowed;
     .rdrDayNumber span {
        color: var(--primary-text-muted);
        
      }
    }

    .rdrDayNumber span{
      color: var(--primary-text-color);
    }

  }
}
.image-wrapper{

  .lazyLoder{
    width: inherit;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .image{
    object-fit: contain;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    border-radius: 0px;
  }
}

.button-wrapper-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.invalid-message-text{
  color: var(--danger-color);
}

.mobile-activity-table-wrapper{

  margin-bottom: 70px;
  margin-top: 1rem;

  .mobile-activity-list{
   
    margin-bottom: 1rem;
    padding: 1rem;

    .contents-wrapper{
      display: flex;
      justify-content: space-between;

      span{
        color: var(--primary-text-color);
      }

      .activity-list-image-wrapper{

        display: flex;
        gap: 1rem;
        justify-content: center;
        align-items: center;
    
        img{
          max-width: 15vw;
          border-radius: 5px;
        }
  
        .item-info{
          display: grid;
          gap: 0.5rem;
          place-items: baseline;
        }
      }

    }

    &:hover{
      transition: all 0.2s ease 0s;
    box-shadow: rgba(4 ,17, 29 , 0.25) 0px 0px 8px 0px;
    background-color: var(--color-shade-4);
    }

    .activity-collapse-content{
      margin-top: 1rem;
      border-top: 1px solid var(--primary-boder-light);
      padding-top: 1rem;

      .activity-collapse-content-wrapper{
        display: flex;
        gap: 1rem;
        justify-content: space-between;
      }

      span{
        color: var(--primary-text-color);
      }

    }
   
  }
}

.no-btn{
  background-color: transparent;
  outline: none;
  border: none;
  color: var(--primary-text-color);
  &:hover , &:focus , &:active{
    background-color: transparent;
    outline: none;
    border: none;
  }
}

.activityLoader {

  .desktop-activityLoader{
    .desktop-activityLoader-wrapper{
      display: grid;
      grid-template-columns: repeat(5 , 1fr);
      gap: 2rem;

      .table-body-item{
        span{
          width: 100%;
        }
      }

    } 
  }
}

.converted_price{

  margin-bottom: 1rem;

  &.right{
    text-align: end;
  }

}

.complete-listing-right-sec{
  .total-price-card{
    justify-content: end;
  }
}

.price-view-sec-wrapper{
  display: grid;
  padding: 2rem;
  gap: 1.5rem;
}

.image-price{

  &.column{
    gap: 5px;
    flex-direction: column;
  }

  &.end{
    align-items: flex-end !important;
  }

}

.trending-slider{

  .slick-track{

    display: flex !important;

    .slick-slide{
      height: inherit !important;

      & > div{
        height: 100%;

        & > div{
          height: 100%;
        }

      }

    }

  }
}

.custom-checkbox {
  .check-field [type="checkbox"] {
    position: absolute !important;
    left: -9999px;
  }
  
  /* Posición de la label */
  .check-field [type="checkbox"] + label {
    position: relative !important;
    padding-left: 2rem !important;
    cursor: pointer !important;
  }
  
      .check-field [type="checkbox"] + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: 1.5rem;
        height: 1.5rem;
        border: 4px solid var(--pinkColor);
        transform: translateY(-50%);
        border-radius: 50%;
      }
  
      .check-field [type="checkbox"] + label:after {
          content: '';
          position: absolute;
          width: 12px;
          height: 12px;
          top: 50%;
          left: 6px;
          font-size: 1.3em;
          line-height: 0.8;
          background-color: deeppink;
          transition: all .2s;
          border-radius: 50%;
      }
  
      /* Checked */
      .check-field [type="checkbox"]:not(:checked) + label:after {
          opacity: 0;
          transform: scale(0) ;
      }
  
      .check-field [type="checkbox"]:checked + label:after {
          opacity: 1;
          transform: scale(1) translateY(-50%);
      }
}
